import styled from 'styled-components';
import { borderGrey } from '../../constants/theme';

export const Container = styled.div`
  .home-icon {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 0 0 !important;
    .accessibility-feature {
      display: none;
    }
  }
  .item {
    &:last-child {
      .nav-item-li {
        padding-right: 2px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 992px) {
    .home-icon {
      position: relative;
      padding: 20px;
    }
  }
  .navbar {
    padding: 7px 15px;
  }
  .nav-item-li {
    padding: 0 14px;
  }

  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .home-icon {
      left: 8px;
    }
  }
  //IPAD PRO LANDSCAPE
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    .navbar {
      padding: 0px 2px 0px 0px;
    }
    .home-icon {
      left: 0px;
    }
  }
  @media (min-width: 320px) and (max-width: 992px) {
    .nav-item-li {
      padding: 0 0;
    }
    .burger-toggler {
      svg {
        font-size: 50px;
        color: #eb3c96;
      }
      .accessibility-feature {
        display: none;
      }
      &:hover {
        outline: none;
      }
      &:active {
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
    .navbar-expand-lg {
      justify-content: flex-end;
    }
    .navbar-collapse {
      position: absolute;
      font-weight: 300;
      top: 45px;
      align-items: flex-start;
      z-index: -2;
      right: 1000%;
      padding: 15px 28px;
      width: 100%;
      transition: all 0.3s ease;
      display: block;
      background-color: white;
    }
    .navbar-collapse.collapsing {
      height: auto !important;
      margin-left: 150%;
      transition: all 0.7s ease;
      display: block;
    }
    .navbar-collapse.show {
      width: 100%;
      left: 0;
      height: calc(100vh - 93px);
      overflow: auto;
    }
    .navbar-nav {
      .item {
        padding: 24px 0;
        border-bottom: 1px solid ${borderGrey};
      }
    }
  }
  @media (min-width: 320px) and (max-width: 481px) {
    .navbar-collapse.show {
      height: calc(100vh - 116px);
    }
  }
  //
  @media (min-width: 581px) and (max-width: 992px) and (orientation: landscape) {
    .navbar-collapse.show {
      width: 100%;
      left: 0;
      height: calc(100vh - 93px) !important;
      overflow: auto;
      .navbar-nav {
        .item {
          padding: 7px 0;
        }
      }
    }
  }
  //small mobile devices like IphoneSE, samsung S5
  @media (min-width: 320px) and (max-width: 580px) and (orientation: landscape) {
    .navbar-collapse.show {
      .navbar-nav {
        .item {
          padding: 4px 0;
        }
      }
    }
  }

  @media (min-width: 993px) and (max-width: 1024px) {
    .navbar {
      padding-left: 30px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  ul {
    display: flex;
    list-style: none;
    padding: 0;
    flex-direction: column;
    li {
      margin-bottom: 20px;
    }
  }
`;

import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { ContainerHeader } from './header.css';
import Nav from 'components/nav';
import { StaticQuery, graphql } from 'gatsby';
import { NavLink, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import { IoIosSearch } from 'react-icons/io';
import ProgramWebsitesMenu from '../program-websites-menu/program-websites-menu';
import { registerTrackEvent } from '../../helpers/tagManager';

const Header = ({ data, logo, title, mainMenu, selectedLanguage }) => {
  const [isProgramWebsitesMenuOpen, setIsProgramWebsitesMenuOpen] = useState(
    false
  );
  const [isMobile, setIsMobile] = useState(false);
  const [mailTo, setMailTo] = useState(data.site.siteMetadata.adverseEventsMail);

  const checkWindowWidthSize = () => {
    if (
      typeof window !== 'undefined' &&
      window.innerWidth &&
      window.innerWidth < 992
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        checkWindowWidthSize();
      });

      window?.location?.hostname?.includes('merckgroup')
        ? setMailTo(data.site.siteMetadata.adverseEventsMail_merck)
        : setMailTo(data.site.siteMetadata.adverseEventsMail);
    }
    checkWindowWidthSize();
    return window.removeEventListener('resize', () => {
      checkWindowWidthSize();
    });
  }, []);
  return (
    <div>
      <ContainerHeader>
        <Row className={'header-menu '}>
          <Col xs={12} sm={12} md={12} lg={12} className={'top-menu'}>
            <div className={'header-container'}>
              <NavLink
                className={'logo-img'}
                href={`/${selectedLanguage}/`}
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Logo',
                })}
              >
                <Img
                  fluid={logo.childImageSharp.fluid}
                  alt={title}
                  className="header-logo-img"
                  durationFadeIn={0}
                  loading="eager"
                />
                <span className={'accessibility-feature'}>Logo</span>
              </NavLink>
              <NavLink
                className={'top-links'}
                href={`/${selectedLanguage}/find-a-trial/`}
                onClick={registerTrackEvent({
                  category: 'SiteSearch',
                  action: 'Open',
                })}
              >
                Find a Trial
                <IoIosSearch />
              </NavLink>
              <NavLink
                className={'top-links'}
                href={`mailto: ${mailTo}`}
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Adverse Event',
                })}
              >
                Adverse Event
              </NavLink>
            </div>
          </Col>
          <Col className={'menu-list'} xs={12} sm={12} md={12} lg={12}>
            {mainMenu && mainMenu.length && (
              <div className={'header-container'}>
                <Nav
                  selectedLanguage={selectedLanguage}
                  menuItems={data.pageMetadataJson.pages
                    .filter(page => {
                      return (
                        page.pageLang === selectedLanguage &&
                        mainMenu.includes(page.url)
                      );
                    })
                    .sort((a, b) => {
                      return mainMenu.indexOf(a.url) - mainMenu.indexOf(b.url);
                    })}
                  isProgramWebsitesMenuOpen={isProgramWebsitesMenuOpen}
                  toggleProgramWebsitesMenu={() =>
                    setIsProgramWebsitesMenuOpen(!isProgramWebsitesMenuOpen)
                  }
                  isMobile={isMobile}
                />
              </div>
            )}
          </Col>
          {isProgramWebsitesMenuOpen && !isMobile ? (
            <ProgramWebsitesMenu />
          ) : null}
        </Row>
      </ContainerHeader>
    </div>
  );
};

const HeaderWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitle
            mainMenu
            adverseEventsMail
            adverseEventsMail_merck
          }
        }
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
);

export default HeaderWithQuery;

Header.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  mainMenu: PropTypes.array.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  logo: PropTypes.any.isRequired,
};

const PRODUCTION_SCRIPT = '//assets.adobedtm.com/b5a2629b807e/b410dab6c4ea/launch-7030688fc112.min.js';
const UAT_SCRIPT = '//assets.adobedtm.com/b5a2629b807e/b410dab6c4ea/launch-4e7259ccdaf6-staging.min.js';

export const tagManagerScript = (location) => {
  if (
    location.hostname.includes('localhost') ||
    location.hostname.includes('dev-') ||
    location.hostname.includes('uat-') ||
    location.hostname.includes('merck-engage.app-trialscope.com')
  ) {
    return UAT_SCRIPT;
  } else {
    return PRODUCTION_SCRIPT;
  }
};

export const trackEvent = (event) => {
  const { category, action, label, value, ...props } = event;
  if (typeof window != 'undefined' && typeof window._satellite != 'undefined') {
    if (value && !Number.isInteger(value)) {
      // eslint-disable-next-line no-console
      console.error(
        '[TagManager] The "value" parameter must be a integer',
        event
      );
    }

    window._satellite.track('adHocEvent', {
      eventCategory: category,
      eventAction: action,
      ...(label ? { eventLabel: label } : {}),
      ...(value || value == 0 ? { eventValue: value } : {}),
      ...props,
    });
  } else {
    // eslint-disable-next-line no-console
    console.error(
      '[TagManager] TagManage script has not been uploaded yet. Make sure you call this method after the application is loaded.'
    );
  }
};

// Wrapper trackEvent to register tracking for future calls
export const registerTrackEvent = (event) => () => trackEvent(event);

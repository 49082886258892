import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  background-color: #503291;
  padding: 0;
  img {
    height: 66px;
    width: 140px;
  }

  .footer-content {
    max-width: 1220px;
    width: 100%;
    margin: 80px 20px;
    background-color: #ffffff;
  }

  .nav-section {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 20px;
  }

  .row {
    width: 100%;
    padding: 0 25px;
    margin: 0;
  }
  @media (max-width: 767px) {
    .footer-content {
      margin: 0;
    }
    &.special-margin {
      margin-top: 60px;
      margin-bottom: 72px;
    }
    .row {
      padding: 0 5px;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .nav-section {
      border: none;
      margin-top: 17px;
      padding-bottom: 40px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 15px;
      margin-right: 15px;
    }
    .nav-social {
      border-bottom: 1px solid #e1e1e1;
    }
  }
`;

export const SocialSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  a {
    display: inline-block;
    background-color: #494953;
    border-radius: 50%;
    margin-left: 20px;
    img {
      height: 36px;
      width: 40px;
    }
  }
  .facebook {
    &:hover {
      background-color: #3b5998;
    }
  }
  .twitter {
    &:hover {
      background-color: #00acee;
    }
  }
  .linkedin {
    &:hover {
      background-color: #0077b5;
    }
  }
  @media (min-width: 320px) and (max-width: 575px) {
    justify-content: flex-start;
    a {
      margin-left: 0;
      margin-right: 20px;
    }
  }
`;

export const LogoSection = styled.div`
  width: 100%;
  padding: 40px;
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 20px;
  }
`;

export const PowerZone = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 40px 40px;
  p {
    font-size: 10px;
    color: #000000;
  }
  .power-zone {
    display: flex;
    align-items: center;
    p {
      margin-right: 2px;
    }
  }
  .psmr-code {
    border-left: 1px solid #e1e1e1;
    margin-left: 8px;
    padding-left: 8px;
  }
  button {
    color: #000000;
    font-size: 10px;
    backgound-color: #ffffff;
    border: none;
    margin: 0;
    padding: 0;
    text-transform: none;
    img {
      margin-left: 12px;
      max-height: 56px;
      height: auto;
      width: auto;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 450px) {
    flex-direction: column;
    .power-zone {
      margin-top: 20px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 20px 20px 40px 20px;
  }
`;

export const NavSection = styled.div`
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: #ffffff;
  a {
    color: #eb3c96;
    font-size: 14px;
    letter-spacing: 2px;
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 900;
    &:hover {
      color: #a52a69;
    }
  }
  .nav-link {
    padding: 0;
    &:hover {
      color: #a52a69;
      text-decoration: underline;
    }
  }
  button {
    display: flex;
    color: #eb3c96;
    font-size: 14px;
    letter-spacing: 2px;
    border: none;
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 900;
    margin: 0;
    padding: 0;
    text-align: left;
    &:hover {
      color: #a52a69;
      text-decoration: none;
    }
    img {
      display: none;
    }
  }
`;

import styled, { createGlobalStyle } from 'styled-components';
import { Row } from 'reactstrap';
import {
  daisyTitle,
  black,
  headerPurple,
  lightGrey,
  titlePink,
  white,
  darkText,
  lightGrayText,
  borderGrey,
  recruiting,
  completed,
  terminated,
  notRecruiting,
  resultsGrey,
  locationHeader,
  borderLocation,
} from 'constants/theme';
import { UncontrolledPopover } from 'reactstrap';

export default createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }
  
  @import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');
  
  body {
    font-family: 'Lato', sans-serif;
  }
  .website-container {
    min-height: 66vh;
    padding-top: 155px;
    justify-content: center;
    display: flex;
  }
  @media print {
    .website-container {
      padding-top: 10PX !Important;
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape ) {
    .website-container {
      min-height: 66vh;
      padding-top: 143px;
      justify-content: center;
      display: flex;
    }
  }
  //IPAD Pro Landscape
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .website-container {
      padding-top: 141px;
    }
  }
  @media (max-width: 992px) {
    .website-container {
      padding-top: 116px;
    }
  }

  [data-reach-dialog-overlay] {
    z-index: 9999;
  }
  .content-container {
    max-width: 1220px;
    width: 100%;
  }
  .flex-box-container {
    justify-content: center;
    display: flex;
  }

  .osano-cm-dialog {
    .osano-cm-denyAll{
      display: none;
    }
    .close-button {
      position: absolute;
      top: 0;
      right: 6px;
      cursor: pointer;
      font-size: 24px;
    }
  }
  .osano-cm-content__message {
    max-height: 110px;
    visibility: hidden;
    font-size: 0;
    &::before {
      content: "This website uses 'cookies' to give you the best, most relevant experience. Using this website means you’re Ok with this. You can change which cookies are set at any time - and find out more about our ";
      visibility: visible;
      font-size: 16px;
    }
  }
  .osano-cm-info-dialog__info {    
    .osano-cm-header {
      visibility: hidden;
      font-size: 0;
      &::before {
        content: "Your settings for the cookies";
        visibility: visible;
        font-size: 16px;
        padding: 16px 12px 0;
      }
    }
    .osano-cm-info__info-views {
      .osano-cm-info-views__view {
        .osano-cm-view__list {
          li {
            &:nth-child(1) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Necessary cookies";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies are necessary for the website to operate. Our website cannot function without these cookies and they can only be disabled by changing your browser preferences.";
                  }
                }
              }
            }
            &:nth-child(2) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Comfort";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies allow us to provide more comfort for you. For example, previously searched products or services can be reloaded again after revisiting our website and you won’t need to enter all the details again. We can also detect if you need assistance with using our website and therefore offer you direct customer support.";
                  }
                }
              }
            }
            &:nth-child(3) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Personalization";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "These cookies are used to display personalized content matching your interests. We can display personalized and relevant services to ensure you are always up to date on related offers.";
                  }
                }
              }
            }
            &:nth-child(4) {
              label {
                .osano-cm-toggle__label {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 14px;
                    visibility: visible;
                    content: "Statistics";
                  }
                }
              }
              .osano-cm-list-item__description {
                p {
                  visibility: hidden;
                  font-size: 0;
                  &::before {
                    font-size: 12px;
                    visibility: visible;
                    content: "In order to continuously improve our website, we anonymously track data for statistical and analytical purposes. With these cookies we can, for example, track the number of visits or the impact of specific pages of our web presence and therefore optimize our content.";
                  }
                }
              }
            }
            &:nth-child(5) {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export const Fonts = styled.div`
  width: 100%;
  h1 {
    color: ${daisyTitle};
    font-size: 52px;
    line-height: 62px;
    font-weight: 900;
  }
  h2 {
    color: ${headerPurple};
    font-size: 30px;
    line-height: 36px;
    font-weight: 900;
    &.faqTitle {
      padding-bottom: 40px;
      font-size: 42px;
      line-height: 52px;
    }
  }
  h2.pink {
    color: ${titlePink};
    font-size: 30px;
    line-height: 36px;
  }
  p {
    color: ${black};
    font-size: 18px;
    line-height: 32px;
  }
  p.white {
    color: ${white};
  }
  .whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  @media (max-width: 767px) {
    h2.faqTitle {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;

export const HeroHome = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 60px 0;
  .row {
    max-width: 1250px;
    width: 100%;
  }
  h1 {
    margin-bottom: 20px;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-top: 10px;
  }
  .mobileContent {
    display: none;
  }
  @media (min-width: 994px) {
    padding: 30px 0 80px 0;
    img {
      margin-top: 75px;
      max-height: 280px;
    }
  }
  @media (max-width: 993px) {
    .desktopContent {
      display: none;
    }
    .mobileContent {
      display: block;
    }
    img {
      top: 7%;
    }
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    margin: 0 5px;
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    padding: 40px 0 60px 0;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 40px 0 40px;
    h1 {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 30px;
    }
    img {
      margin-top: 30px;
    }
  }
`;

export const HomeSectionStyle = styled.div`
  display: flex;
  justify-content: center;
  .row {
    max-width: 1250px;
    width: 100%;
    padding: 40px 0;
  }
  h2 {
    padding-bottom: 40px;
  }
  a:hover {
    text-decoration: none;
  }
  .participate-section {
    p {
      display: flex;
      justify-content: flex-end;
      color: #767680;
      font-size: 14px;
      font-style: italic;
      line-height: 27px;
    }
    h2 {
      padding-top: 40px;
    }
    .video-section {
      padding: 0 20px;
    }
  }
  .item {
    margin-left: -15px;
  }
  .transparency-section {
    .gatsby-image-wrapper {
      width: 90%;
    }
    .title-section {
      min-height: 54px;
    }
    p {
      letter-spacing: -0.3px;
      line-height: 27px;
    }
    @media (max-width: 1258px) {
      .title-section {
        min-height: 76px;
      }
    }
  }
  .disclaimer {
    button {
      margin: 0;
      position: relative;
      background-color: #eb3c96;
      border: none;
      color: #ffffff;
      font-size: 11px;
      font-weight: 900;
      letter-spacing: 2px;
      line-height: 13px;
      text-align: center;
      border-radius: 0;
      &:hover {
        background-color: #a52a69;
      }
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    .participate-section {
      h2 {
        padding-top: 0;
        padding-bottom: 30px;
      }
    }
  }
  @media (max-width: 480px) {
    h2 {
      padding-bottom: 10px;
      font-size: 24px;
      line-height: 30px;
    }
    .transparency-section {
      .title-section {
        min-height: 24px !important;
      }
    }
    .item {
      margin-left: 0;
      figure {
        margin-top: 30px;
      }
    }

    .participate-section {
      h2 {
        padding-top: 0;
        padding-bottom: 40px;
      }
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .row {
      padding-bottom: 0;
    }
    .item {
      margin-left: 0;
      padding: 0;
    }
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    padding: 0 5px;
  }
  @media (min-width: 320px) and (max-width: 765px) {
    .participate-section {
      p {
        text-align: right;
      }
    }
    .transparency-section {
      p {
        margin-top: 16px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 765px) and (orientation: landscape) {
    .transparency-section {
      padding: 40px 0 20px 0;
      .content-item {
        margin-bottom: 20px;
      }
    }
  }
`;

export const HomeFindTrial = styled.div`
  display: flex;
  justify-content: center;
  background-color: #503291;
  .row {
    max-width: 1250px;
    width: 100%;
    padding: 40px 0;
  }
  .divModal {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 97%;
    text-align: left;
  }
  .dropdown-menu.show {
    padding: 0;
    .dropdown-item {
      padding: 5px;
    }
  }
  input {
    border-radius: 0;
  }
  .hcp-section {
    margin-top: 40px;
    .gatsby-image-wrapper {
      width: 70%;
    }
    p {
      margin-top: 20px;
      color: #ffffff;
      font-size: 18px;
      line-height: 32px;
    }
  }
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .hcp-section {
      .gatsby-image-wrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 1024px) {
    padding: 0 5px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    h2.pink {
      font-size: 24px;
      line-height: 30px;
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    margin-top: 0;
    .hcp-section {
      margin-top: 30px;
    }
  }
`;

export const ShadowSec = styled.div`
  height: 222px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: ${lightGrey};
  &.disclaimer {
    height: 118px;
  }
  &.video {
    height: 208px;
  }
  &.participate {
    height: 240px;
  }
  &.phases {
    height: 350px;
  }
  &.stepsCT {
    height: 520px;
  }
  @media (min-width: 992px) and (max-width: 1158px) {
    &.phases {
      height: 390px;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    height: 204px;
    &.video {
      height: 188px;
    }
  }
  // md style
  @media (min-width: 767px) and (max-width: 990px) {
    &.participate {
      height: 275px;
    }
    &.phases {
      height: 390px;
    }
    &.stepsCT {
      height: 530px;
    }
  }
  // ipad shadow
  @media (min-width: 767px) and (max-width: 770px) {
    &.transparency-section {
      height: 240px;
    }
  }
  // ipad shadow
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    &.transparency-section {
      height: 187px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    height: 200px;
    &.video {
      height: 268px;
    }
    &.participate {
      height: 240px;
    }
    &.phases {
      height: 420px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    height: 260px;
    &.participate {
      height: 300px;
    }
    &.phases {
      height: 540px;
    }
    &.stepsCT {
      height: 230px;
    }
  }
`;
export const ShadowSecDetails = styled.div`
  height: 365px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: ${lightGrey};
`;

// START FAQS PAGE STYLE
export const FaqsHero = styled(Row)`
  padding: 40px 0;
  h1 {
    color: ${headerPurple};
    padding-right: 5%;
    padding-top: 20px;
    font-weight: 900;
  }
  img {
    // max-width: 801px;
    max-height: 452px;
    max-width: 100%;
  }
  p.footnote {
    text-align: right;
    color: #767680;
    font-size: 14px;
    font-style: italic;
    line-height: 27px;
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px 20px;
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 20px;
    h1 {
      padding-right: 0%;
      padding-top: 0;
      padding-bottom: 30px;
      font-size: 32px;
      line-height: 38px;
    }
  }
`;

// END FAQS PAGE STYLE

// START What are clinical trial page PAGE STYLE
export const HeroWhatCTStyle = styled(Row)`
  padding: 40px 0;
  .hero-video {
    .gatsby-image-wrapper {
      max-height: 451px;
    }
  }
  h1 {
    margin-top: 20px;
    padding-bottom: 20px;
  }
  p.permission {
    display: flex;
    justify-content: flex-end;
    text-align: end;
    color: #767680;
    font-size: 14px;
    font-style: italic;
    line-height: 27px;
  }
  &.hcp-Hero {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    .row {
      width: 100%;
      max-width: 1250px;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 20px 0 40px 0;
    .heroText {
      padding-bottom: 30px;
      h1 {
        padding-bottom: 20px;
      }
    }
    &.hcp-Hero {
      padding: 20px 20px;
    }
  }
  //TABLET Landscape
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    padding: 40px 0;
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    &.hcp-Hero {
      padding: 40px 20px;
    }
    h1 {
      margin-top: 0;
      font-size: 32px;
      line-height: 38px;
      padding-bottom: 40px;
    }
    .heroText {
      margin-top: 0px;
      margin-bottom: 40px;
    }
  }
`;
export const TabletMarginStyle = styled.div`
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media (max-width: 767px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

export const SliderStyle = styled.div`
  h2 {
    position: relative;
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 42px;
    line-height: 52px;
    font-weight: 900;
  }
  .align-elements {
    padding: 0;
  }
  .phases-subtitle {
    margin-bottom: 40px;
  }
  .phases-text {
    width: 282px;
    ul {
      list-style: none;
      padding-left: 35px;
      li {
        color: #000000;
        font-size: 16px;
        line-height: 27px;
        margin-top: 16px;
      }
      li::before {
        content: '•';
        color: #503291;
        padding-right: 9px;
        font-size: 22px;
        display: inline-block;
        margin-left: -1em;
      }
    }
    p {
      font-size: 16px;
      line-height: 27px;
      a {
        color: ${black};
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 2px;
        border-bottom: 2px solid ${titlePink};
        margin-left: 5px;
      }
      a:hover {
        text-decoration: none;
        border-bottom: 2px solid #a52a69;
      }
    }
  }
  .phase-title {
    color: ${headerPurple};
    font-family: Lato;
    font-size: 18px;
    font-weight: 900;
    line-height: 26px;
    margin: 15px 0;
  }
  .gatsby-image-wrapper {
    margin-bottom: 20px;
    width: 242px;
    height: 212px;
    img {
    }
    & > img {
      display: none;
    }
  }
  .alice-carousel__next-btn-item {
    span {
      display: none;
    }
  }
  .alice-carousel__next-btn-item::before {
    content: '';
    width: 32px;
    height: 70px;
    background-image: url(/images/Arrow_Right.png);
    background-size: 32px;
    background-repeat: no-repeat;
    position: absolute;
    right: -30px;
    top: 160px;
  }
  .alice-carousel__prev-btn-item {
    span {
      display: none;
    }
  }
  .alice-carousel__prev-btn-item::before {
    content: '';
    width: 32px;
    height: 70px;
    background-image: url(/images/Arrow_Left.png);
    background-size: 32px;
    background-repeat: no-repeat;
    position: absolute;
    left: -60px;
    top: 160px;
  }
  @media (max-width: 1360px) and (min-width: 1024px) {
    .alice-carousel__prev-btn-item::before {
      left: 0px;
      background-size: 26px;
    }
    .alice-carousel__stage {
      padding: 0 25px;
    }
    .alice-carousel__next-btn-item::before {
      right: 2px;
      background-size: 26px;
    }
    .align-elements {
      margin-left: 10px !important;
    }
  }
  @media (max-width: 699px) and (min-width: 320px) {
    .alice-carousel__stage-item {
      .gatsby-image-wrapper,
      .phase-title,
      .phases-text {
        margin-left: auto;
        margin-right: auto;
        max-width: 280px;
      }
      .col-item {
        padding: 0;
        margin-left: -50px;
      }
    }
  }
  @media (max-width: 1023px) {
    .alice-carousel__prev-btn-item::before {
      left: 0px;
    }
    .alice-carousel__stage {
      padding: 0 50px;
    }
    .alice-carousel__next-btn-item::before {
      right: 10px;
    }
  }
  @media (max-width: 767px) {
    h2,
    .phases-subtitle {
      margin-left: -15px;
      margin-right: -15px;
    }
    h2 {
      font-size: 28px;
      line-height: 36px;
      padding-top: 64px;
      padding-bottom: 40px;
    }
    .alice-carousel__next-btn-item::before {
      background-size: 20px;
    }
    .alice-carousel__prev-btn-item::before {
      background-size: 20px;
    }
    .phase-title {
      margin-top: 20px;
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    .align-elements {
      padding: 0 15px;
    }
  }
  //TABLET
  @media (max-width: 767px) and (min-width: 1024px) {
    .alice-carousel__stage-item {
      width: 250px !important;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait) {
    .alice-carousel__stage-item {
      .gatsby-image-wrapper {
        width: 216px;
        height: 189px;
      }
      .phase-title {
        margin-top: 40px;
      }
    }
  }
`;

export const VideoContainer = styled.div`
  padding: 40px 0;
  .footnote {
    text-align: right;
    color: #767680;
    font-size: 14px;
    font-style: italic;
    line-height: 27px;
  }
`;

export const FlipCardStyle = styled(Row)`
  max-width: 950px;
  padding: 0 0 40px 0;
  .stepsTitle {
    padding-bottom: 20px;
    padding-top: 40px;
    padding-left: 15px;
    font-size: 42px;
    line-height: 52px;
  }
  h2 {
    font-size: 18px;
    line-height: 26px;
    padding: 20px 0 40px 0;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    padding: 15px;
  }
  .back-flip {
    height: 212px;
    display: flex;
    justify-content: left;
    align-items: center;
    background-color: ${white};
  }
  .gatsby-image-wrapper {
    height: 212px;
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  //TABLET LANDSCAPE
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }

  //MOBILE
  @media (max-width: 767px) {
    .stepsTitle {
      font-size: 28px;
      line-height: 36px;
      padding-bottom: 40px;
    }
    h2 {
      padding-bottom: 30px;
    }
  }
`;
// END What are clinical trial page PAGE STYLE

export const HeroWP = styled.div`
  padding: 40px 0;
  h1 {
    margin-top: 20px;
  }
  p {
    display: flex;
    justify-content: flex-end;
    text-align: end;
    color: #767680;
    font-size: 14px;
    font-style: italic;
    line-height: 27px;
  }
  @media (max-width: 1240px) {
    margin: 0 20px;
  }
  @media (max-width: 1024px) {
    h1 {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    h1 {
      font-size: 32px;
      line-height: 38px;
    }
  }
`;
export const FindSection = styled.div`
  padding: 40px 0;
  a {
    color: #ffffff;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 13px;
    text-align: center;
    background-color: #eb3c96;
    padding: 12px 14px;
    :hover {
      text-decoration: none;
      background-color: #a52a69;
    }
  }
  button {
    color: #000000;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 15px;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #eb3c96;
    &:hover {
      border-bottom: 2px solid #a52a69;
    }
  }
  .find-section {
    display: flex;
    align-items: center;
    &.left {
      justify-content: flex-end;
    }
  }
  @media (max-width: 575px) {
    .find-section {
      justify-content: center;
      &.left {
        justify-content: center;
        margin-bottom: 30px;
      }
    }
  }
`;

export const SimpleSearch = styled.div`
  width: 100%;
  .row {
    width: 100%;
    padding: 0;
  }
  .divModal {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 97%;
    text-align: left;
  }
  button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    color: ${white};
    font-size: 16px;
    line-height: 27px;
    border-bottom: 2px solid;
    border-radius: 0;
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: space-between;
    text-transform: none;
    opacity: 0.6;
    svg {
      color: #eb3c96;
      opacity: 1;
    }
    &:focus {
      outline: none;
    }
    &.results-btn {
      border-bottom: 1px solid ${lightGrayText};
      color: ${black};
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }
  input {
    border: none;
    background-color: transparent;
    color: ${white};
    font-size: 16px;
    border-bottom: 2px solid;
    text-transform: capitalize;
    width: 100%;
    line-height: 27px;
    height: 29px;
    padding: 0;
    opacity: 0.6;
    ::placeholder {
      color: ${white};
    }
    &:disabled {
      ::placeholder {
        color: ${lightGrayText};
      }
    }
    &.results-input {
      border-radius: 0;
      border-bottom: 1px solid ${lightGrayText};
      color: ${black} !important;
    }
    &:focus {
      outline: none;
    }
  }
  .select-button {
    align-items: center;
  }
  .search-button {
    background-color: ${titlePink};
    height: 38px;
    width: 83px;
    font-size: 11px;
    font-weight: 900;
    letter-spacing: 2px;
    line-height: 38px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    opacity: 1;
    margin-right: 20px;
    text-transform: uppercase;
    &:hover {
      background-color: #a52a69;
    }
    &.results-search-btn {
      margin-right: 0px;
      padding: 0 15px;
      width: inherit;
      &:hover {
        background-color: #a52a69;
      }
    }
  }
  .search-col {
    margin-top: 42px;
    display: flex;
    align-items: center;
    &.results-search-btn {
      margin-top: 38px;
      padding-left: 15px;
    }
  }
  a {
    display: inline-block;
    color: ${white};
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 15px;
    border-bottom: 2px solid ${titlePink};
    padding: 0;
    &:hover {
      border-bottom: 2px solid #a52a69;
    }
  }
  label {
    color: ${white};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 13px;
    margin-top: 20px;
    &.textResults {
      color: ${lightGrayText};
      white-space: nowrap;
    }
  }
  p {
    color: ${white};
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 13px;
    margin-top: 20px;
    &.textResults {
      color: ${lightGrayText};
      white-space: nowrap;
    }
  }
  h2 {
    margin-bottom: 20px;
  }
  .dropdown-menu.show {
    width: 100%;
    z-index: 1;
    .dropdown-item {
      margin: 0;
      border-bottom: 1px solid #808080;
      color: ${black};
      &:hover {
        color: #eb3c96;
      }
    }
    &.results-dropdown-menu.show {
      background-color: ${white};
      .dropdown-item {
        color: ${black};
        padding: 5px;
        border-bottom: 1px solid #808080;
        &:hover {
          background-color: ${white};
          color: ${titlePink};
        }
      }
    }
  }
  .results-keyword-section {
    padding: 0 15px 0 5px;
    label {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1px;
      color: ${lightGrayText};
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 13px;
      margin-top: 20px;
    }
  }
  .resultsPostal {
    padding: 0 15px;
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    .results-search-btn {
      justify-content: center;
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    .resultsPostal {
      padding: 0 5px;
    }
    .search-col {
      &.results-search-btn {
        margin-top: 30px;
        padding-left: 5px;
      }
    }
    .results-keyword-section {
      padding: 0 5px 0 5px;
    }
  }
`;
//START RESULTS PAGE STYLE//
export const ResultsGeneralStyle = styled(Row)`
  max-width: 1250px;
  width: 100%;
  h1 {
    font-size: 30px;
    line-height: 36px;
    padding-top: 40px;
    font-family: 'Lato';
  }
  .pagination-info {
    font-size: 16px;
    font-family: 'Lato';
    line-height: 27px;
    padding-bottom: 10px;
    color: ${black};
    &.bottom {
      padding: 38px 0 36px 0;
    }
    &.onePage {
      padding-left: 20px;
    }
  }
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  .dropdown-menu.show.results-dropdown-menu.show {
    padding: 0;
  }
  .filter-modal-btn {
    display: none;
  }
  @media (max-width: 991px) {
    .deskContent {
      display: none;
    }
    .filter-modal-btn {
      display: block;
    }
    .social-sharing > div {
      padding-top: 20px;
      justify-content: flex-start;
      a,
      button {
        margin: 0 10px 0 0;
      }
    }
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
    .resultsTable-col {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  //TABLET LANDSCAPE
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .resultsTable-col {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    .resultsTable-col {
      .heroSection {
        padding: 0 20px;
      }
    }
    .pagination-info {
      padding: 0 20px;
    }
  }
  @media (max-width: 340px) {
    h1 {
      font-size: 26px;
    }
  }
`;
export const FilterMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: flex;
    width: 100%;
    button {
      position: absolute;
      z-index: 2;
      right: 20px;
      margin-top: -27px;
      border: none;
      background-color: white;
      color: #000000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 17px;
      text-transform: uppercase;
      img {
        height: 17px;
        width: 17px;
        margin-bottom: -4px;
      }
    }
  }
`;
export const FilterDiv = styled.div`
  h2 {
    margin-top: 106px;
    margin-bottom: -15px;
    color: #503291;
    font-family: 'Lato';
    font-size: 30px;
    line-height: 36px;
    font-weight: 900;
  }
  .header-sec {
    display: flex;
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    z-index: 10;
    top: 0;
    justify-content: flex-end;
    height: 66px;
    border-bottom: 1px solid #e1e1e1;
    align-items: center;
    svg {
      margin-right: 12px;
      height: 45px;
      width: 45px;
      color: #eb3c96;
    }
  }
  .content-sec {
    background-color: #ffffff;
    padding: 0 20px;
    margin-bottom: 110px;
    .reset-button {
      display: none;
    }
    label {
      margin-bottom: 0;
    }
    h3 {
      margin: 0;
    }
  }
  .footer-sec {
    height: 72px;
    background-color: #ffffff;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05),
      0 -10px 20px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    z-index: 10;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: space-around;
    .apply {
      height: 38px;
      width: 153px;
      background-color: #eb3c96;
      border: none;
      color: #ffffff;
      font-size: 11px;
      letter-spacing: 2px;
      font-family: Lato;
      text-align: center;
      text-transform: uppercase;
    }
    .reset {
      border: none;
      background-color: #ffffff;
      color: #000000;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 15px;
      text-transform: uppercase;
      font-family: Lato;
      border-bottom: 2px solid #eb3c96;
    }
  }
`;
export const ResultsFindTrial = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${white};
  .row {
    max-width: auto;
    width: 100%;
    padding: 20px 0 40px 0;
  }
  .search-button.results-search-btn {
    border: none;
    color: ${white};
  }
  .form-row {
    button {
      color: #1b1b25;
      border-bottom: 2px solid #e1e1e1;
      &:disabled {
        color: #808080;
      }
    }

    input {
      color: #1b1b25;
      border-bottom: 2px solid #e1e1e1;
      ::placeholder {
        color: #1b1b25;
      }
      &:disabled {
        ::placeholder {
          color: #808080;
        }
      }
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    .row {
      padding: 20px 5px 40px 5px;
    }
  }
`;

export const ResultsTable = styled.div`
  .start-table {
    font-family: 'Lato';
    .accesibility-feature {
      display: none !important;
    }
    .results-table {
      .table-header {
        height: 52px;
        line-height: 47px;
        color: ${darkText};
        font-weight: 900;
        font-size: 12px;
        letter-spacing: 1px;
        background-color: ${resultsGrey};
        text-transform: uppercase;
        tr {
          border-bottom: 1px solid ${borderGrey};
          .conditions {
            padding-left: 20px;
          }
        }
        svg {
          font-weight: 900;
          font-size: 15px;
        }
        .location {
          position: relative;
          svg {
            position: absolute;
            top: 15px;
            right: 0;
          }
        }
      }
      .table-body {
        .result-second-row {
          .more-information {
            font-size: 16px;
            line-height: 27px;
            position: relative;
            border-top: 1px solid #e9e9ed;
            padding: 10px 20px 20px 0;
            margin: 0 20px;
            span {
              font-weight: bold;
              display: block;
              float: left;
              padding-right: 4px;
            }
            .clamp-lines {
              .clamp-lines__button {
                outline: none;
                right: 0;
                top: 8px;
                position: absolute;
                color: ${titlePink};
                background-color: transparent;
                border: none;
                line-height: 27px;
                padding: 0;
              }
            }
          }
          &.row-unpaired {
            background-color: ${lightGrey};
          }
        }
        .result-row {
          color: ${black};
          height: 121px;
          .condition-section {
            width: 25%;
            vertical-align: top;
            line-height: 27px;
            font-size: 16px;
            font-family: Lato;
            padding: 20px 0 0 20px;
          }
          .status-section {
            padding-top: 20px;
            padding-right: 15px;
            font-size: 16px;
            font-family: Lato;
            .status-icons {
              align-items: center;
              display: flex;
              line-height: 30px;
              white-space: nowrap;
            }
            .recstatus_recruiting::before {
              content: '⬤';
              color: ${recruiting};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .recstatus_study_completed::before {
              content: '⬤';
              color: ${completed};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .recstatus_enrolling_by_invitation::before {
              content: '⬤';
              color: ${recruiting};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .recstatus_terminated_withdrawn,
            .recstatus_terminated:before {
              content: '⬤';
              color: ${terminated};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .recstatus_not_yet_recruiting::before {
              content: '⬤';
              color: ${notRecruiting};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
          }
          .requirements-section {
            width: 20%;
            font-family: Lato;
            padding-top: 20px;
            font-size: 16px;
            vertical-align: top;
            .genderLookup {
              margin-left: 30px;
            }
            .ageRange {
              line-height: 30px;
              white-space: nowrap;
              align-items: center;
              display: flex;
              font-weight: 900;
            }
            .female::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/images/gender-female.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
            }
            .male::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/images/gender-male.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
            }
            .both::before {
              content: '';
              width: 25px;
              height: 25px;
              background-image: url(/images/male_and_female.png);
              background-size: 25px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
          }
          .distance-section {
            vertical-align: top;
            padding: 20px 20px 0 0;
            font-size: 16px;
            font-family: Lato;
            line-height: 27px;
            width: 22%;
          }
          .details-section {
            vertical-align: top;
            padding: 18px 20px 15px 20px;
            width: 13%;
            .learn-more {
              background-color: ${titlePink};
              color: ${white};
              font-size: 11px;
              line-height: 13px;
              letter-spacing: 2px;
              font-weight: 900;
              display: block;
              text-align: center;
              padding: 13px 15px 13px 15px;
              max-width: 129px;
              width: 100%;
              white-space: nowrap;
              margin: 5px 0;
              text-transform: uppercase;
              &:hover {
                text-decoration: none;
                background-color: #a52a69;
              }
            }
            button {
              -webkit-appearance: none;
              background-color: ${titlePink};
              border: none;
              border-radius: 0;
              cursor: pointer;
              font-family: inherit;
              color: ${white};
              font-size: 11px;
              line-height: 13px;
              letter-spacing: 2px;
              font-weight: 900;
              display: block;
              text-align: center;
              padding: 13px 12px 13px 12px;
              max-width: 129px;
              min-width: 129px;
              white-space: nowrap;
              margin: 5px 0;
              text-transform: uppercase;
              &:active,
              &:focus {
                outline: none;
              }
              &:hover {
                text-decoration: none;
                background-color: #a52a69;
              }
            }
          }
          &.row-unpaired {
            background-color: ${lightGrey};
          }
        }
      }
    }
  }
  .pagination-col {
    padding: 38px 15px 36px 15px;
    .pagination {
      justify-content: center;
      .page-item {
        margin: 0 10px;
        .page-link {
          color: ${black};
          font-size: 18px;
          line-height: 32px;
          background-color: transparent;
          border: none;
          padding: 0 5px;
          &:focus {
            box-shadow: none;
          }
          &.text-link {
            color: ${titlePink};
            font-size: 12px;
            font-weight: 900;
            line-height: 32px;
            &:hover {
              color: rgb(204, 62, 135);
            }
          }
        }
        &.disabled {
          display: none;
        }
        &.active {
          border-bottom: 6px solid ${titlePink};
        }
      }
    }
  }
  .no-results-message {
    font-size: 16px;
    font-family: Lato;
    color: ${black};
  }

  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    .no-results-message {
      padding: 0 20px 20px 0;
    }
    .start-table {
      .results-table {
        .table-header {
          line-height: 26px;
          height: 52px;
          .location {
            position: relative;
            line-height: 26px;
            svg {
              position: absolute;
              top: 7px;
              right: -5px;
            }
          }
        }
        .table-body {
          .result-row {
            height: 121px;
            .condition-section {
              width: 21%;
              padding-right: 5px;
            }
            .status-section {
              width: 22%;
              .status-icons {
                white-space: normal;
                line-height: 21px;
              }
            }
            .requirements-section {
              width: 24%;
              padding-right: 5px;
            }
            .distance-section {
              vertical-align: top;
              padding: 15px 0 0 0;
              line-height: 22px;
              width: 18%;
            }
            .details-section {
              width: 18%;
              padding: 20px 0 0 0;
            }
          }
        }
      }
    }
    .form-row {
      width: auto;
    }
  }
  //TABLET LANDSCAPE
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .start-table {
      .results-table {
        .table-header {
          line-height: 52px;
          .location {
            position: relative;
            line-height: 20px;
            svg {
              position: absolute;
              top: 17px;
              right: -5px;
            }
          }
        }
        .table-body {
          .result-row {
            .status-section {
              width: 20%;
            }
            .details-section {
              text-align: center !important;
            }
          }
        }
      }
    }
  }
  //IPAD Pro Portrait
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .details-section {
      width: 18% !important;
      text-align: center !important;
    }
    .start-table {
      .results-table {
        .table-header {
          .location {
            position: relative;
            line-height: 26px;
            svg {
              position: absolute;
              top: 7px;
              right: -5px;
            }
          }
        }
      }
    }
  }
  //IPAD Pro Landscape
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
    .distance-section {
      width: 18% !important;
    }
    .details-section {
      width: 18% !important;
      text-align: center !important;
    }
  }
  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    .result-second-row {
      border: none;
      padding: 0;
    }
    .no-results-message {
      padding: 0 20px 20px 20px;
    }
    .start-table {
      .results-table {
        .table-header {
          height: 2px !important;
          background-color: rgba(225, 225, 225, 1);
          .location {
            svg {
              display: none;
            }
          }
        }
        .table-body {
          margin: 0 -15px;
          .result-row {
            height: auto !important;
            border: none;
            padding: 0 10px;
            .condition-section {
              width: auto !important;
              padding-top: 30px;
              padding-bottom: 30px;
            }
            .status-section {
              width: auto !important;
              padding-bottom: 30px;
              .status-icons {
                white-space: normal;
                line-height: 21px;
              }
            }
            .requirements-section {
              width: auto !important;
              padding-bottom: 30px;
              .ageRange {
                margin-left: 0px;
              }
            }
            .distance-section {
              width: auto;
              padding-bottom: 30px;
              svg {
                display: none;
              }
            }
            .details-section {
              padding: 0 0 30px 0;
              width: auto !important;
            }
          }
          .pagination-bottom {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
  //MOBILE Landscape
  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .start-table {
      .results-table {
        .table-header {
          .sortable {
            line-height: 40px;
            padding-right: 15px;
            &.location {
              line-height: 18px;
            }
          }
        }
        .table-body {
          .result-row {
            height: auto !important;
            border: none;
            padding: 0 15px;
            .condition-section {
              width: auto !important;
              padding-top: 30px;
              padding-bottom: 30px;
            }
            .status-section {
              width: auto !important;
              padding-bottom: 30px;
            }
            .requirements-section {
              width: auto !important;
              padding-bottom: 30px;
              .ageRange {
                line-height: 21px;
                white-space: normal;
              }
              .female::before {
                padding-right: 25px;
              }
              .male::before {
                padding-right: 25px;
              }
              .both::before {
                padding-right: 25px;
              }
            }
            .distance-section {
              width: auto;
              padding-bottom: 15px;
            }
            .details-section {
              padding: 15px 0 30px 0;
              width: auto !important;
            }
          }
          .pagination-bottom {
            border: none;
            padding: 0;
          }
        }
      }
    }
  }
  @media (max-width: 466px) {
    .start-table {
      .results-table {
        .table-body {
          .result-row {
            .pivoted {
              padding-left: calc(40% + 10px) !important;
            }
          }
        }
      }
    }
  }
  //IPHONE 6,7,8 landscape
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
    .condition-section {
      padding-top: 15px !important;
    }
  }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .start-table {
      .results-table {
        .table-header {
          height: auto;
          line-height: 22px !important;
          .sortable {
            line-height: 22px !important;
            white-space: nowrap;
          }
        }
      }
    }
  }
`;

//END RESULTS PAGE STYLE//
export const HeroSectionForPatients = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 30px;
  .searchTitle {
    font-weight: 900;
  }
  @media (max-width: 767px) {
    padding: 40px 20px;
  }
`;

export const AdvSearchSection = styled.div`
  h4 {
    font-family: Lato;
    color: #000000;
    font-size: 18px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  h5 {
    color: #808080;
    font-family: Lato;
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 15px;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .accesibility-features {
    display: none !important;
  }
  .row {
    margin: 0;
  }
  button {
    font-family: Lato;
    &:focus {
      outline: none;
    }
  }
  input {
    font-family: Lato;
    color: #808080;
    background-color: transparent;
    border-bottom: 1px solid #808080;
    &:focus {
      outline: none;
    }
    ::placeholder {
      color: #808080;
    }
    &:disabled {
      color: #808080;
    }
  }
  .page-context {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    .content-container {
      & > div {
        padding: 0;
      }
    }
  }
  .box-sec {
    display: flex;
    flex-direction: column;
  }
  .checkboxes-section {
    display: flex;
    flex-wrap: wrap;
    label {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .search-label {
    display: flex;
    justify-content: center;
    height: 80px;
    background-color: #eb3c96;
    align-items: center;
    margin-bottom: 10px;
    input {
      max-width: 1600px;
      width: 100%;
      color: ${white};
      font-family: Lato;
      font-size: 16px;
      height: 29px;
      background-color: transparent;
      border: none;
      margin-left: 10px;
      ::placeholder {
        color: ${white};
        font-size: 16px;
        line-height: 27px;
        opacity: 0.5;
      }
      :-webkit-autofill {
        background-color: #eb3c96 !important;
      }
    }
    svg {
      height: 20px;
      width: 20px;
      color: ${white};
    }
    @media (max-width: 1240px) {
      .content-container {
        padding-left: 15px;
      }
    }
  }
  .searchBtn {
    height: 38px;
    width: 83px;
    font-weight: 900;
    background-color: #eb3c96;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 13px;
    text-align: center;
    color: ${white};
    border: none;
    text-transform: uppercase;
    :hover {
      background-color: #a52a69;
    }
  }
  .resetBtn {
    color: #000000;
    font-size: 12px;
    background-color: transparent;
    letter-spacing: 2px;
    text-transform: uppercase;
    line-height: 15px;
    border: none;
    border-bottom: 2px solid #eb3c96;
    padding: 0;
    margin-left: 20px;
    :hover {
      border-bottom: 2px solid #a52a69;
    }
  }
  .actions-section {
    margin: 40px 0;
  }
  .genderClass {
    margin-top: 6px;
  }
  .gender-types-line {
    margin-left: -15px;
    margin-right: -15px;
  }
  .search-line {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 10px;
    button {
      color: #1b1b25;
      border-bottom: 2px solid #e1e1e1;
      &:disabled {
        color: #808080;
      }
    }
    input {
      color: #1b1b25;
      border-bottom: 2px solid #e1e1e1;
      border-radius: 0;
      height: 29px;
      ::placeholder {
        color: #1b1b25;
      }
      &:disabled {
        ::placeholder {
          color: #808080;
        }
      }
    }
    .dropdown-menu.show {
      background-color: ${white};
      padding: 0;
      z-index: 10;
      .dropdown-item {
        padding: 5px;
        border-bottom: 1px solid #808080;
        &:hover {
          background-color: ${white};
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  @media (max-width: 767px) {
    .page-context {
      padding: 0 20px;
    }
    .age-col {
      margin-top: 10px;
    }
    .search-label {
      .content-container {
        padding-left: 5px;
      }
    }
    .field-direction {
      .checkboxes-section {
        flex-direction: column;
      }
    }
    .filed-phases {
      label {
        min-width: 100px;
      }
    }
    .actions-section {
      position: fixed;
      display: flex;
      justify-content: space-around;
      align-items: center;
      bottom: 0;
      left: 0;
      z-index: 9;
      background-color: ${white};
      width: 100%;
      height: 72px;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05),
        0 -10px 20px 0 rgba(0, 0, 0, 0.1);
      margin: 0;
      .searchBtn {
        width: 153px;
      }
    }
  }
`;
export const DetailsHero = styled.div`
  padding: 40px 0;
  width: 100%;
  .accesibility-feature {
    display: none !important;
  }

  .go-back-button {
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 15px;
    text-transform: uppercase;
    border-bottom: 2px solid ${titlePink};
    background-color: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    display: inline-block;
    padding-left: 0px;
    padding-right: 0px;
    img {
      vertical-align: middle;
      margin-right: 5px;
    }
    &:hover {
      border-bottom: 2px solid #a52a69;
    }
    &:focus {
      outline: none;
    }
  }
  h1 {
    color: ${headerPurple};
    font-weight: 900;
  }
  .main-description {
    padding: 30px 0 10px 0;
    font-size: 18px;
    line-height: 32px;
    color: ${black};
    max-width: 1420px;
  }
  .overall-section {
    padding-top: 15px;
    display: flex;
    .popoverBtn {
      background-color: ${titlePink};
      border-radius: 50%;
      border: none;
      padding: 0 2px;
      display: inline-block;
      align-self: self-start;
      position: relative;
      width: 12px;
      height: 12px;

      svg {
        font-size: 10px;
        position: absolute;
        left: 1px;
        top: 1px;
      }
      &:focus {
        outline: none;
      }
    }
    .status-display {
      align-items: center;
      display: flex;
      font-size: 16px;
      line-height: 27px;
      white-space: nowrap;
      display: inline-block;
    }
    .recruiting::before {
      content: '⬤';
      color: ${recruiting};
      background-size: 16px;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 5px;
    }
    .completed::before {
      content: '⬤';
      color: ${completed};
      background-size: 16px;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 5px;
    }
    .invitation::before {
      content: '⬤';
      color: ${recruiting};
      background-size: 16px;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 5px;
    }
    .terminated_withdrawn,
    .terminated:before {
      content: '⬤';
      color: ${terminated};
      background-size: 16px;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 5px;
    }
    .not::before {
      content: '⬤';
      color: ${notRecruiting};
      background-size: 16px;
      background-repeat: no-repeat;
      display: inline-block;
      margin-right: 5px;
    }
  }
  .id-cols {
    a {
      color: ${black};
      font-size: 16px;
      line-height: 27px;
    }
    p {
      color: ${black};
      font-size: 16px;
      line-height: 27px;
    }
    h3 {
      color: ${darkText};
      font-size: 16px;
      line-height: 27px;
      font-weight: 900;
      margin-right: 5px;
      display: inline-block;
    }
    .popoverBtn {
      background-color: ${titlePink};
      border-radius: 50%;
      border: none;
      padding: 0 2px;
      display: inline-block;
      align-self: self-start;
      position: relative;
      width: 12px;
      height: 12px;
      margin-top: -15px;

      svg {
        font-size: 10px;
        position: absolute;
        left: 1px;
        top: 1px;
      }
      &:focus {
        outline: none;
      }
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px 20px;
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 40px 5px;
    .status-bar-desktop {
      display: flex;
      align-items: center;
    }
    h1.details-title {
      margin-top: 10px;
      font-size: 34px;
      line-height: 44px;
    }
    .hero-title-col {
      h1 {
        font-size: 34px;
        line-height: 44px;
      }
    }
    .overall-section {
      padding-top: 30px;
    }
    .id-cols {
      margin-top: 18px;
    }
  }
  @media screen and (max-width: 575px) {
    .social-sharing > div {
      padding-top: 10px;
      a,
      button {
        margin-bottom: 0;
      }
    }
  }
`;
export const TrialPurposeStyle = styled.div`
  padding: 40px 0 76px 0;
  border-top: 1px solid ${borderGrey};
  margin: 0 15px 0 15px;
  h2 {
    padding-bottom: 30px;
  }
  p {
    margin-bottom: 30px;
  }
  .publicationsContainer {
    padding-bottom: 40px;
    margin-bottom: 40px;
    .showBtn {
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 15px;
      text-transform: uppercase;
      border-bottom: 2px solid ${titlePink};
      background-color: transparent;
      border-left: none;
      border-right: none;
      border-top: none;
      display: inline-block;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 8px;
      img {
        vertical-align: middle;
        margin-right: 5px;
      }
      &:hover {
        border-bottom: 2px solid #a52a69;
      }
      &:focus {
        outline: none;
      }
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 40px 20px 76px 20px;
  }
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }

  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    margin: 0 20px 0 20px;
  }
`;
export const TrialSummaryStyle = styled.div`
  .criteria-print {
    margin: 0 15px;
    padding-bottom: 40px;
    display: none;
    h2 {
      margin: 15px 0;
    }
    pre {
      size: auto;
      color: #000000;
      border: none;
      white-space: pre-line;
      padding: 0;
      font-family: inherit;
      font-weight: 300;
      font-size: 18px;
      line-height: 32px;
    }
  }
  .trial-summary-row {
    padding: 40px 15px;
  }
  .criteria-Col {
    padding: 40px 15px;
    button {
      background-color: transparent;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 2px solid ${titlePink};
      border-radius: 0%;
      padding: 0;
      margin: 20px 0;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 15px;
      &:hover {
        border-bottom: 2px solid #a52a69;
      }
    }
  }
  .detail-card {
    background-color: ${headerPurple};
    height: 195px;
    text-align: center;
    margin-top: 30px;
    .card-body {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }
  .popoverBtn {
    position: absolute;
    top: 11px;
    right: 11px;
    background-color: ${titlePink};
    border-radius: 50%;
    border: none;
    padding: 0 2px;
    width: 12px;
    height: 12px;
    svg {
      color: ${headerPurple};
      font-size: 10px;
      position: absolute;
      left: 1px;
      top: 1px;
    }
    &:focus {
      outline: none;
    }
    .accesibility-feature {
      display: none !important;
    }
  }
  .summary-icon {
    margin-top: 9px;
    &.condition {
      width: 62px;
      height: 72px;
    }
    &.gender {
      width: auto;
      height: 68px;
    }
    &.age {
      width: 68px;
      height: 69px;
    }
    &.capsula {
      width: 58px;
      height: 43px;
    }
    &.study {
      width: 52px;
      height: 70px;
    }
    &.phase {
      width: 65px;
      height: 66px;
    }
    &.calendar {
      width: 61px;
      height: 66px;
    }
  }
  .img-holder {
    min-height: 101px;
  }
  h4 {
    font-size: 16px;
    line-height: 27px;
    color: ${white};
  }
  .publicationsContainer {
    padding: 0 15px;
    margin: 40px 0px;
    h2 {
      margin-bottom: 30px;
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 20px;
  }

  //TABLET LANDSCAPE
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .detail-card {
      height: 195px;
    }
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    .summaryTitle {
      padding-left: 20px;
      padding-right: 20px;
    }
    .trial-summary-row {
      padding-bottom: 0;
    }
    .cards-holder {
      padding-left: 20px;
      padding-right: 20px;
    }
    .criteria-Col {
      padding-left: 20px;
      padding-right: 20px;
    }
    .criteria-Col button {
      margin-bottom: 0;
    }
    .publicationsContainer {
      padding: 0 20px;
    }
    .detail-card {
      height: 251px;
      .img-holder {
        min-height: 140px;
      }
      .summary-icon {
        &.condition {
          width: 102px;
          height: 102px;
        }
        &.gender {
          width: auto;
          height: 89px;
        }
        &.age {
          width: 98px;
          height: 99px;
        }
        &.capsula {
          width: 88px;
          height: 73px;
        }
        &.study {
          width: 82px;
          height: 100px;
        }
        &.phase {
          width: 95px;
          height: 96px;
        }
        &.calendar {
          width: 91px;
          height: 96px;
        }
      }
    }
  }
  @media print {
    .criteria-Col {
      display: none;
    }
    .criteria-print {
      display: block;
      pre {
        page-break-inside: auto;
      }
    }
  }
`;
export const MapSectionStyle = styled.div`
  .map-section {
    padding-left: 0;
    padding-right: 0;
  }
  h2 {
    position: relative;
    padding-top: 40px;
  }
  .map-locations {
    margin-top: 40px;
    margin-bottom: 40px;
    height: 400px;
    .marker-info-window {
      line-height: 16px;
      strong {
        font-weight: 900;
        line-height: 20px;
      }
      a {
        color: rgb(204, 62, 135);
        &:hover {
          text-decoration: none;
          color: #a52a69 !important;
        }
      }
    }
  }
  .location-holder {
    .thead-mobile {
      background-color: ${locationHeader};
      border-bottom: 1px solid ${borderLocation};
      th {
        color: ${darkText};
        font-weight: 900;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 15px;
        padding: 15px 2px 30px 2px;
        text-transform: uppercase;
        &.first {
          padding-left: 20px;
        }
      }
    }
    tbody {
      .location-row {
        height: 114px;
        td {
          font-size: 16px;
          line-height: 27px;
          color: ${black};
          width: 12%;
          vertical-align: middle;
          padding: 0 2px;
          &.location {
            padding: 0 20px;
            width: 20%;
            word-break: break-word;
          }
          &.status-line {
            .status-content {
              align-items: center;
              display: flex;
              font-size: 16px;
              line-height: 27px;
              white-space: nowrap;
              display: inline-block;
            }
            .recruiting::before {
              content: '⬤';
              color: ${recruiting};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .completed::before {
              content: '⬤';
              color: ${completed};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .enrolling_by_invitation::before {
              content: '⬤';
              color: ${recruiting};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .terminated_withdrawn,
            .terminated:before {
              content: '⬤';
              color: ${terminated};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .not::before {
              content: '⬤';
              color: ${notRecruiting} !important;
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
            .not_yet_recruiting::before {
              content: '⬤';
              color: ${notRecruiting};
              background-size: 16px;
              background-repeat: no-repeat;
              display: inline-block;
              margin-right: 5px;
            }
          }
          &.modal-status {
            text-align: center;
            button {
              margin-top: 0;
              margin-bottom: 0;
              padding: 13px 14px;
              background-color: ${titlePink};
              border: none;
              font-size: 11px;
              letter-spacing: 2px;
              line-height: 13px;
              text-align: center;
              color: ${white};
              font-weight: 900;
              border-radius: 0%;
              &:hover {
                background-color: #a52a69;
              }
            }
          }
        }
        &.row-unpaired {
          background-color: ${locationHeader};
        }
        &.showRow {
          display: table-row;
        }
        &.showTotalRows {
          display: table-row;
        }
        &.hideRow {
          display: none;
        }
      }
    }
  }

  .contact-print {
    display: none !important;
  }
  .button-holder {
    width: 100%;
    text-align: center;
    button {
      padding: 0;
      border-left: none;
      border-right: none;
      border-top: none;
      background-color: transparent;
      text-transform: uppercase;
      letter-spacing: 2px;
      line-height: 15px;
      font-size: 12px;
      color: ${black};
      border-bottom: 2px solid ${titlePink};
      margin: 40px 0 40px 0;
      &:focus {
        outline: none;
      }
      &:hover {
        border-bottom: 2px solid #a52a69;
      }
    }
  }

  @media print {
    .gm-style div > img {
      position: absolute;
    }
    .location-row {
      &.no-print {
        display: none !important;
      }
    }
    .contact-print {
      display: table-row !important;
    }
    .location-holder {
      thead {
        display: table-row-group !important;
      }
    }
    .button-holder {
      display: none;
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    .mapSection {
      padding-left: 35px;
      padding-right: 35px;
    }
    tbody {
      td {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 640px) {
    .mapSection {
      padding-left: 0;
      padding-right: 0;
      h2 {
        padding-left: 20px;
        padding-right: 20px;
      }
      .map-holder {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .location-holder {
      .location-row {
        height: auto !important;
        border: none;
        padding: 30px 20px 20px 20px;
        td {
          width: 100% !important;
        }
        .pivoted {
          padding: 10px 20px;
        }
        td.modal-status {
          text-align: left !important;
          button {
            margin-top: 20px !important;
          }
        }
        td.location {
          padding-bottom: 10px !important;
        }
        &.showRow {
          display: block !important;
        }
        &.showTotalRows {
          display: block !important;
        }
      }
    }
  }
`;
export const DetailsSocialStyle = styled.div``;
export const DocumentsSection = styled.div`
  padding: 0 15px 40px 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .documents-holder {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 20px;
    .attachments {
      display: inline-block;
      background-color: ${titlePink};
      padding: 12px 14px;
      button {
        margin: 0px;
        background-color: transparent;
        border: 0;
        padding: 0;
        display: flex;
        align-items: center;
        svg {
          margin-top: 0px;
        }
      }
      a,
      button {
        font-size: 11px;
        letter-spacing: 2px;
        line-height: 13px;
        text-align: center;
        color: ${white};
        font-weight: 900;
        margin-left: 5px;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
        }
      }
      &:hover {
        background-color: #a52a69;
        text-decoration: none;
      }
      svg {
        margin-right: 10px;
        margin-top: -4px;
      }
    }
    .popoverHolder {
      display: inline-block;
      vertical-align: top;
      margin-top: -12px;
      margin-left: 3px;
      .popoverBtn {
        background-color: ${titlePink};
        border-radius: 50%;
        border: none;
        padding: 0 2px;
        display: inline-block;
        align-self: self-start;
        position: relative;
        width: 12px;
        height: 12px;
        svg {
          font-size: 10px;
          position: absolute;
          left: 1px;
          top: 1px;
        }
        &:focus {
          outline: none;
        }
        .accesibility-feature {
          display: none !important;
        }
      }
    }
  }
  @media print {
    .documents {
      text-decoration: none;
    }
  }
  //TABLET
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 0 35px 20px 35px;
    .documents-holder {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0 15px 20px 15px;
    .documents-holder {
      margin-bottom: 20px;
    }
  }
  @media (max-width: 360px) {
    .documents-holder {
      margin-right: 0;
    }
  }
`;
export const UncontrolledPopoverStyledSections = styled(UncontrolledPopover)`
  .popover {
    background-color: ${white};
    z-index: 1;
    color: ${black};
    border: none;
    max-width: 420px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 30px 40px 0 rgba(0, 0, 0, 0.2);
    .popover-inner {
      .popover-header {
        font-size: 30px;
        font-weight: 900;
        text-align: left;
        line-height: 36px;
        color: ${headerPurple};
        background-color: ${white};
        border: none;
        margin: 42px 38px 18px 38px;
        padding: 0;
        font-family: Lato;
      }
      .popover-body {
        color: ${black};
        font-size: 18px;
        line-height: 32px;
        text-align: left;
        border: none;
        margin: 0 38px 30px 38px;
        padding: 0;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .popover {
      max-width: 180px;
      .popover-inner {
        .popover-header {
          font-size: 14px !important;
          line-height: 26px !important;
        }
        .popover-body {
          font-size: 12px !important;
          line-height: 18px !important;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .popover {
      max-width: 270px;
      .popover-inner {
        .popover-header {
          font-size: 16px !important;
          line-height: 28px !important;
        }
        .popover-body {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }
    }
  }
`;

export const ContentWp = styled.div`
  padding-bottom: 40px;
  h2 {
    margin: 40px 0;
  }
  h3 {
    margin-top: 10px;
    color: #503291;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  ul {
    list-style: none;
    padding-left: 35px;
    li {
      color: #000000;
      font-size: 16px;
      line-height: 27px;
      margin-top: 16px;
    }
    li::before {
      content: '•';
      color: #503291;
      padding-right: 9px;
      font-size: 22px;
      display: inline-block;
      margin-left: -1em;
    }
  }
  .content-box {
    border-top: 4px solid #503291;
    h3 {
      font-weight: 900;
    }
  }
  @media (max-width: 1240px) {
    margin: 0 20px;
  }
  @media (max-width: 991px) {
    h2 {
      margin-bottom: 10px;
    }
    .sec-box {
      margin-top: 30px;
    }
  }
  @media (min-width: 320px) and (max-width: 480px) {
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }
`;
export const TabSection = styled.div`
  justify-content: center;
  display: flex;
  //mobile
  @media (min-width: 320px) and (max-width: 766px) {
    display: none;
  }
`;
export const HcpTabStyle = styled.div`
  max-width: 1250px;
  width: 100%;
  padding: 40px 0 30px 0;
  border-bottom: 1px solid #e1e1e1;
  .nav-tabs {
    justify-content: center;
    flex-wrap: nowrap;
    .nav-item {
      padding: 0 15px;
      border-bottom: 6px solid transparent;
      display: flex;
      align-items: center;
      .nav-link {
        text-align: center;
        max-width: 282px;
        font-weight: 700;
        font-size: 22px;
        line-height: 32px;
        border: none;
        padding: 0 0 25px 0;
        color: ${black};
        &.active {
          outline: none;
          border: none;
          color: ${black};
          border-color: transparent;
        }
        &:hover {
          cursor: pointer;
          color: ${titlePink};
        }
        &:focus {
          outline: -webkit-focus-ring-color auto 1px;
        }
        &:focus-visible {
          outline: -webkit-focus-ring-color auto 1px;
        }
      }
      &.activeItem {
        outline: none;
        border: none;
        color: ${black};
        border-bottom: 6px solid ${titlePink};
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
  }
  .tab-content {
    .tab-pane {
      padding: 60px 15px 0 15px;
      .card-body {
        padding: 0;
        border-radius: 0;
        border: none;
        .card-text {
          h2 {
            padding-bottom: 40px;
          }
          p {
            margin-bottom: 16px;
          }
          .subOrderedLi {
            list-style: none;
            margin-left: 50px;
            padding-bottom: 10px;
            a {
              color: ${titlePink};
              &:hover {
                text-decoration: none;
                color: #a52a69 !important;
              }
            }
          }
          .subOrderedLi::before {
            content: '•';
            color: ${headerPurple};
            padding-right: 10px;
            font-size: 18px;
            display: inline-block;
            margin-left: -23px;
          }
          ul {
            padding-left: 25px;
            li {
              list-style: none;
              padding-bottom: 10px;
              a {
                color: ${titlePink};
                text-decoration: none;
                &:hover {
                  text-decoration: none;
                  color: #a52a69 !important;
                }
              }
            }
            li::before {
              content: '•';
              color: ${headerPurple};
              padding-right: 10px;
              font-size: 18px;
              display: inline-block;
              margin-left: -20px;
            }
          }
          figcaption {
            padding-top: 10px;
            color: #808080;
            font-size: 12px;
            line-height: 15px;
            font-style: italic;
            &.last-element {
              padding-bottom: 10px;
            }
          }
        }
        video {
          width: 385px;
        }
      }
      .video-holder {
        max-width: 386px;
        width: 100%;
        padding: 0;
        border-radius: 0;
        border: none;
        padding-top: 76px;
        .footnote {
          font-size: 13px;
          line-height: 27px;
          color: #767680;
          font-style: italic;
          text-align: right;
        }
      }
      .img-holder {
        float: right;
        padding: 0;
        border-radius: 0;
        border: none;
        img {
          width: 386px;
          height: 338px;
        }
      }
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .nav-tabs {
      .nav-item {
        padding: 0 10px;
        .nav-link {
          max-width: 220px;
        }
      }
    }
    .tab-content {
      padding: 0 20px;
      .tab-pane {
        padding: 60px 0 0;
        .img-holder {
          img {
            width: 226px;
            height: 190px;
          }
        }
      }
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) and (orientation: landscape) {
    .tab-content {
      padding: 0 20px;
      .tab-pane {
        padding-top: 60px;
        .img-holder {
          img {
            width: 386px !important;
            height: 338px !important;
          }
        }
      }
    }
  }
`;
export const StatisticSection = styled.div`
  padding: 40px 0;
  background-color: ${lightGrey};
  justify-content: center;
  display: flex;
  .row {
    max-width: 1250px;
    width: 100%;
  }
  .footnote {
    font-size: 13px;
    line-height: 16px;
    color: #808080;
    font-style: italic;
    padding-top: 16px;
    strong {
      font-weight: 900;
      font-style: normal;
    }
  }
  .content-holder {
    padding: 40px 0;
    .gatsby-image-wrapper {
      width: 282px;
      height: 247px;
    }
    p {
      font-size: 16px;
      line-height: 27px;
      max-width: 282px;
      padding-top: 20px;
      strong {
        font-weight: 900;
      }
    }
  }
  .difference-text {
    color: ${titlePink};
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    font-weight: 700;
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    padding: 40px 5px;
  }
  //Mobile
  @media (min-width: 320px) and (max-width: 767px) {
    .row {
      margin: 0 5px;
    }
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
    .footnote {
      padding-bottom: 15px;
      strong {
        display: block;
      }
    }
    .content-holder {
      padding: 15px 0;
    }
    .difference-text {
      padding-top: 25px;
    }
  }
`;
export const TargetSection = styled.div`
  padding: 40px 0;
  justify-content: center;
  display: flex;
  .row {
    max-width: 1220px;
    width: 100%;
    .footnote {
      font-size: 13px;
      line-height: 16px;
      color: #808080;
      font-style: italic;
      padding-top: 16px;
      strong {
        font-weight: 900;
        font-style: normal;
      }
    }
  }
`;
export const CarouselStyle = styled.div`
  padding: 40px 0;
  background-color: ${white};
  justify-content: center;
  display: flex;
  .row {
    max-width: 1250px;
    width: 100%;
  }
  .mini-carousel {
    display: flex;
    button {
      border: none;
      background-color: transparent;
      width: 15%;
      padding: 0;
      img {
        width: 38px;
        height: 38px;
      }
      &.prev {
        text-align: left;
      }
      &.next {
        text-align: right;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        opacity: 0.8;
      }
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
    }
    div {
      justify-content: center;
      display: flex;
      width: 70%;
      p {
        line-height: 32px;
        text-align: center;
        strong {
          font-weight: 900;
        }
      }
    }
  }
  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .row {
      padding: 0 5px;
      height: 96px;
    }
    .mini-carousel {
      button {
        width: 10%;
      }
      div {
        width: 80%;
        align-items: center;
      }
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    padding: 20px 5px 40px 5px;
    .mini-carousel {
      button {
        width: 10%;
        img {
          width: 24px;
          height: 24px;
        }
      }
      div {
        width: 80%;
      }
    }
  }
`;
export const CharterSectionStyle = styled.div`
  padding: 40px 0 20px 0;
  justify-content: center;
  display: flex;
  .row {
    max-width: 1250px;
    width: 100%;
  }
  .text-col {
    padding-bottom: 40px;
    .footnote {
      font-size: 13px;
      line-height: 16px;
      color: #808080;
      font-style: italic;
      padding-top: 16px;
      strong {
        font-weight: 900;
        font-style: normal;
      }
    }
  }
  .img-holder {
    .mobile-img {
      display: none;
    }
    .desktop-img {
      display: block;
    }
  }

  //TABLET
  @media (min-width: 767px) and (max-width: 1024px) {
    .row {
      padding: 0 5px;
    }
  }

  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    padding: 40px 5px;
    .text-col {
      h2 {
        font-size: 24px;
        line-height: 30px;
      }
      .footnote {
        strong {
          display: block;
        }
      }
    }
    .img-holder {
      .mobile-img {
        display: block;
      }
      .desktop-img {
        display: none;
      }
    }
  }
`;

export const PageBody = styled.div`
  .hidden {
    display: none;
  }
`;
export const ToggleSection = styled.div`
  display: none;

  //MOBILE
  @media (min-width: 320px) and (max-width: 766px) {
    display: block;
    button {
      width: 90%;
      padding: 27px 0;
      background-color: ${white};
      color: ${black};
      font-size: 22px;
      font-weight: bold;
      line-height: 32px;
      text-align: center;
      border: none;
      margin: 0 20px;
      margin-bottom: 0;
      &.borderPink {
        border-bottom: 6px solid ${titlePink} !important;
      }
      &:focus {
        outline: none;
      }
      &.last-btn {
        border-bottom: 1px solid ${borderGrey};
      }
      &.first-btn {
        border-top: 1px solid ${borderGrey};
      }
      &.middle-btn {
        border-top: 1px solid ${borderGrey};
        border-bottom: 1px solid ${borderGrey};
      }
    }
    .card {
      padding: 60px 5px 17px 5px;
      border: none;
      h2 {
        padding: 30px 0 40px 0;
      }
      p {
        margin-bottom: 16px;
      }
      ul {
        padding-left: 25px;
        li {
          list-style: none;
          padding-bottom: 10px;
          font-size: 18px;
          line-height: 32px;
          a {
            color: ${titlePink};
            text-decoration: none;
          }
        }
        li::before {
          content: '•';
          color: ${headerPurple};
          padding-right: 10px;
          font-size: 18px;
          display: inline-block;
          margin-left: -20px;
        }
      }
      .subOrderedLi {
        list-style: none;
        margin-left: 50px;
        padding-bottom: 10px;
        font-size: 18px;
        line-height: 32px;
        a {
          color: ${titlePink};
          &:hover {
            text-decoration: none;
          }
        }
      }
      .subOrderedLi::before {
        content: '•';
        color: ${headerPurple};
        padding-right: 10px;
        font-size: 18px;
        display: inline-block;
        margin-left: -23px;
      }
      figcaption {
        padding-top: 10px;
        color: #808080;
        font-size: 12px;
        line-height: 15px;
        font-style: italic;
        &.last-element {
          padding-bottom: 10px;
        }
        &.middleFig {
          padding-left: 22px;
        }
        &.video {
          text-align: right;
        }
      }
      &.lastCard {
        padding-top: 10px;
        padding-bottom: 28px;
      }
      &.middleCard {
        border-bottom: 1px solid ${borderGrey};
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 766px) and (orientation: landscape) {
    button {
      width: 94%;
    }
    img {
      width: 335px;
      height: 293px;
    }
  }
`;

export const NotFoundPage = styled.div`
  padding-top: 80px;
  text-align: center;
  &.full-width {
    width: 520px;
    padding: 80px 20px 0 20px;
  }
  h1 {
    font-size: 52px;
    font-weight: 900;
    padding-bottom: 30px;
    color: rgb(80, 50, 145);
  }
  p {
    font-size: 18px;
    line-height: 28px;
  }
  a {
    color: rgb(235, 60, 150);
    font-size: 18px;
    line-height: 24px;
    &:hover {
      text-decoration: none;
      color: #a52a69 !important;
    }
    .accesibility-features {
      display: none !important;
    }
  }
  .details-section {
    margin-top: 15px;
    border-bottom: 1px solid ${borderGrey};
    border-top: 1px solid ${borderGrey};
    margin-bottom: 40px;
    button {
      color: black;
      font-size: 18px;
      font-weight: bold;
      border: 0;
      width: 100%;
      background-color: transparent;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      padding: 10px 20px;
      position: relative;
      &:focus {
        outline: none;
      }
      svg {
        position: absolute;
        right: 20px;
        color: rgb(235, 60, 150);
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    .show {
      display: flex;
      flex-direction: column;
    }
    li {
      text-align: left;
      margin-bottom: 5px;
      &.sub-items {
        margin-left: 20px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
  }
  @media (max-width: 560px) {
    &.full-width {
      width: auto !important;
    }
  }
`;

export const ResearchPipelineSection = styled.div`
  display: flex;
  justify-content: center;
  .container {
    max-width: 1250px;
    width: 100%;
    padding: 40px 0;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    &.participate {
      padding-top: 0;
    }
    span {
      vertical-align: middle;
    }
    a {
      margin-left: 5px;
      border-bottom: 2px solid ${titlePink};
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-decoration: none;
      color: black;
      vertical-align: middle;
      &:hover {
        border-bottom: 2px solid #a52a69;
      }
    }
  }
`;

export const StudyNotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 40px 0 !important;
    text-align: center;
  }
  p {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
  }
  a {
    color: #eb3c96;
    font-weight: bold;
  }
  a:hover {
    color: #a52a69;
  }
  @media (max-width: 767px) {
    p {
      font-size: 16px;
    }
  }
  @media (max-width: 480px) {
    min-height: 100px;
    h1.main-title {
      font-size: 32px;
    }
  }
`;

export const accent = '#f90000';
export const white = '#ffffff';
export const black = '#000000';
export const headerPurple = '#503291';
export const borderGrey = '#e1e1e1';
export const logoCiclam = '#DB3A87';
export const daisyTitle = '#502E93';
export const lightGrey = '#F5F5F5';
export const titlePink = '#EB3C96';
export const darkText = '#1B1B25';
export const lightGrayText = '#808080';
export const recruiting = '#a5cd50';
export const terminated = '#e61e50';
export const completed = '#0F69AF';
export const notRecruiting = '#2dbecd';
export const resultsGrey = '#f3f3f7';
export const locationHeader = '#f8f8fc';
export const borderLocation = '#e9e9ed';
export const borderContactUS = '#efeff2';

export const genderIcons = {
  GENDER_ALL: 'url(/images/male_and_female.png)',
  GENDER_FEMALE: 'url(/images/female.png)',
  GENDER_MALE: 'url(/images/male.png)',
  Male: 'url(/images/male.png)',
  Female: 'url(/images/female.png)',
  Both: 'url(/images/male_and_female.png)',
};
export const genderDefaultIcon = 'url(/images/female_male_big.png)';

export const statusIcons = {
  RECSTATUS_STUDY_COMPLETED: 'url(/images/completed.png)',
  RECSTATUS_TERMINATED: 'url(/images/terminated.png)',
  RECSTATUS_RECRUITING: 'url(/images/recruiting.png)',
  RECSTATUS_RECRUITMENT_COMPLETED: 'url(/images/other.pnh)',
  RECSTATUS_UNMAPPED: 'url(/images/terminated.png)',
  RECSTATUS_NOT_YET_RECRUITING: 'url(/images/other.png)',
  RECSTATUS_OTHER: 'url(/images/other.png)',
  'Study Completed': 'url(/images/completed.png)',
  'Terminated / Withdrawn': 'url(/images/terminated.png)',
  Recruiting: 'url(/images/recruiting.png)',
  Withdrawn: 'url(/images/terminated.png)',
  'Not Yet Recruiting': 'url(/images/other.png)',
  'Not Recruiting': 'url(/images/other.png)',
  'Recruitment Complete': 'url(/images/other.png)',
  Other: 'url(/images/other.png)',
  Completed: 'url(/images/completed.png)',
  'Terminated (Halted Prematurely)': 'url(/images/terminated.png)',
  Terminated: 'url(/images/terminated.png)',
  'Active, Not recruiting': 'url(/images/other.png)',
  'Active, not recruiting': 'url(/images/other.png)',
  'Approved for marketing': 'url(/images/completed.png)',
};

export const statusDefaultIcon = 'url(/images/terminated.png)';

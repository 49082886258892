import styled from 'styled-components';

export const RedirectContent = styled.div`
  padding: 10px 10px 20px 10px;
  h1 {
    color: #503291;
    font-size: 22px;
    font-weight: 900;
    line-height: 32px;
    font-family: Lato;
  }
  p {
    color: #1b1b25;
    font-size: 16px;
    line-height: 27px;
    font-family: Lato;
    margin-bottom: 45px;
    margin-top: 16px;
  }
  a {
    background-color: #eb3c96;
    color: #ffffff;
    font-size: 11px;
    letter-spacing: 2px;
    line-height: 13px;
    padding: 12px 14px 13px 14px;
    margin-bottom: 10px;
    &:hover {
      text-decoration: none;
      background-color: #A52A69;
    }
  }
  a:hover {
    text-decoration: none;
    background-color: #A52A69;
  }
  svg {
    color: #eb3c96 !important;
    height: 30px;
    width: 30px;
    &:hover {
      color: #A52A69 !important;
    }
  }
  .close-icon {
    width: 100%;
    display: inline-block;
  }
  .content-modal {
    padding: 0 30px;
    a {
      font-weight: 900;
    }
  }
`;

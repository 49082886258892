import '@reach/dialog/styles.css';
import VisuallyHidden from '@reach/visually-hidden';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { Button, Close, ConditionsModalDialog } from './modal.css';
import { trackEvent } from '../../helpers/tagManager';

// This component is here only to to showcase the
// React Context integration. No need to keep this if
// you don't require a Modal in your project.
const Modal = ({
  children,
  open,
  autoOpen,
  button,
  showModal,
  hideModal,
  type,
  arrow,
  icon,
  trackEvent: trackEventProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (autoOpen) {
      handleModal();
    }
    document.addEventListener('keydown', e => onKeyDown(e));
    return () => {
      document.removeEventListener('keydown', e => onKeyDown(e));
    };
  }, []);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      disableScrolling(open);
    }
  }, [open]);

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      open && hideModal();
    }
  };

  const disableScrolling = open => {
    // Disables scrolling when the modal is open, as suggested by
    // https://www.w3.org/TR/2017/NOTE-wai-aria-practices-1.1-20171214/examples/dialog-modal/dialog.html
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';

      if (isOpen)
        trackEvent({ category: 'Popup', action: 'Close', ...trackEventProps });

      setIsOpen(false);
    }
  };

  const handleModal = () => {
    trackEvent({
      category: 'Popup',
      action: isOpen ? 'Close' : 'Open',
      ...trackEventProps,
    });

    setIsOpen(!isOpen);
  };

  return (
    <>
      {button ? (
        <Button
          type="button"
          onClick={() => {
            handleModal();
            showModal();
          }}
        >
          {icon && icon.position == 'left' && icon.element}
          <div className={'divModal'}>{button}</div>
          {icon && icon.position == 'right' && icon.element}
          {arrow ? <IoIosArrowDown /> : null}
        </Button>
      ) : (
        undefined
      )}

      <ConditionsModalDialog className={type} isOpen={isOpen && open}>
        <Close
          onClick={() => {
            handleModal();
            hideModal();
          }}
        >
          <VisuallyHidden>Close</VisuallyHidden>
        </Close>
        {children}
      </ConditionsModalDialog>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  autoOpen: PropTypes.bool,
  arrow: PropTypes.bool,
  trackEvent: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number,
  }),
  icon: PropTypes.shape({
    element: PropTypes.element.isRequired,
    position: PropTypes.oneOf(['left', 'right']),
  }),
};

export default Modal;

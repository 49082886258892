import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { RedirectContent } from './redirect.css';
import { FaTimesCircle } from 'react-icons/fa';
import { replaceCompanyName } from '../../constants/config';

const Redirect = ({ data, url, hideModal }) => {

  return (
    <RedirectContent>
      <div className={'close-icon'}>
        <FaTimesCircle
          onClick={() => hideModal()}
          style={{
            fontSize: '30px',
            float: 'right',
            cursor: 'pointer',
            color: '#757575',
          }}
        />
      </div>
      <div className={'content-modal'}>
        <div
          dangerouslySetInnerHTML={{
            __html: replaceCompanyName(
              data.redirectJson.content.childMarkdownRemark.html
            ),
          }}
        />
        <a
          onClick={() => hideModal()}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {replaceCompanyName(data.redirectJson.button)}
        </a>
      </div>
    </RedirectContent>
  );
};

Redirect.propTypes = {
  data: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  hideModal: PropTypes.func,
};

const RedirectQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        redirectJson {
          content {
            childMarkdownRemark {
              html
            }
          }
          button
        }
      }
    `}
    render={data => <Redirect data={data} {...props} />}
  />
);

export default RedirectQuery;

export const reCaptchaKey = () =>
  typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.reCaptchaKey
    ? window.manifest.reCaptchaKey
    : '6Ldsl5YUAAAAABAogZwhhL67wza6PcaGx0tBWbNt';

export const gMapApiKey = () =>
  typeof window !== 'undefined' && window.manifest && window.manifest.gMapApiKey
    ? window.manifest.gMapApiKey
    : 'AIzaSyAFq1fItucvNN8W751EX2dHcankbZcdFdA';

export const tenantId = () =>
  typeof window !== 'undefined' && window.manifest && window.manifest.tenantId
    ? window.manifest.tenantId
    : 90327182;

export const geographicalCoordinatesUrl = (country, postalCode, city, region) =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  `/api/GetLatLon?tenantId=${tenantId()}&country=${country}&postalCode=${postalCode}${
    city ? `&city=${city}` : ''
  }${region ? `&region=${region}` : ''}`;

export const searchTrialUrl = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/search?tenantId=' +
  tenantId() +
  '&_cache=' +
  new Date();

export const subitFormUrl = (type) =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/patientdatarequestform/aws' +
  type +
  '?tenantId=' +
  tenantId();

export const trialDetailsUrl = (studyId) =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/?tenantId=' +
  tenantId() +
  '&uniqueStudyId=' +
  // '&Id=' +
  studyId +
  '&_cache=' +
  new Date();

export const allTrialsIds = () =>
  (typeof window !== 'undefined' &&
  window.manifest &&
  window.manifest.stAPI_baseURL
    ? window.manifest.stAPI_baseURL
    : 'https://uat-stapi.trialscope.com') +
  '/api/study/ids/all?tenantId=' +
  tenantId() +
  '&_cache=' +
  new Date();

export const fileUploadMaxSize = () =>
  ((typeof window !== 'undefined' &&
    window.manifest &&
    window.manifest.upload_max_file_mb) ||
    1) * 1048576; // 1048576 = 1MB

const countryMapper = {
  merck: ['RO', 'DE', 'UK', 'HR'], //add here all countries where merck is available
  emd: ['US', 'CA', 'PR', 'AS', 'GU', 'MP', 'UM', 'VI'], //add here all countries where merck is available
  default: 'emd',
};

let localStorageKey = 'user-country';

if (
  typeof window !== 'undefined' &&
  typeof window.localStorage !== 'undefined'
) {
  let testKey = window.localStorage.getItem('test-country');
  if (testKey) {
    localStorageKey = 'test-country';
  }
}

const isUserAgent = /bot|googlebot|crawler|spider|robot|crawling/i.test(
  typeof window !== 'undefined' ? window.navigator.userAgent : ''
);

export const replaceCompanyName = (objToReplace) => {
  if (!objToReplace) {
    return null;
  }

  if (typeof objToReplace !== 'string') {
    if (typeof objToReplace === 'number') {
      return objToReplace;
    }

    if (Array.isArray(objToReplace)) {
      return objToReplace.map(replaceCompanyName);
    } else {
      Object.keys(objToReplace).forEach((key) => {
        objToReplace[key] = replaceCompanyName(objToReplace[key]);
      });

      return objToReplace;
    }
  }

  let companyName = 'EMD Serono';

  if (isUserAgent) {
    if (
      window.location.hostname &&
      window.location.hostname.indexOf('merckgroup') > 0
    ) {
      companyName = 'Merck';
    }
  } else if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined'
  ) {
    let userCountry = window.localStorage.getItem(localStorageKey);
    if (!userCountry) {
      companyName = 'EMD Serono';
    }

    if (countryMapper.emd.includes(userCountry)) {
      companyName = 'EMD Serono';
    } else {
      companyName = 'Merck';
    }
  }

  return objToReplace
    .replace(/%%COMPANY_NAME%%/g, companyName)
    .replace(/%%COMPANYNAME%%/g, companyName);
};

export const getCompanyData = (object, propertyName) => {
  if (!object) {
    return null;
  }

  if (!Object.prototype.hasOwnProperty.call(object, propertyName)) {
    return null;
  }

  if (isUserAgent) {
    if (
      window.location.hostname &&
      window.location.hostname.indexOf('merckgroup') > 0
    ) {
      return object[`${propertyName}_merck`] || object[propertyName];
    }
    return object[propertyName];
  } else if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined'
  ) {
    let userCountry = window.localStorage.getItem(localStorageKey);
    if (!userCountry) {
      return object[propertyName];
    }

    if (countryMapper.emd.includes(userCountry)) {
      return object[propertyName];
    } else {
      return object[`${propertyName}_merck`] || object[propertyName];
    }
  } else {
    return object[propertyName] || null;
  }
};

// Return current environment
export const getCurrentEnv = () => {
  if (
    typeof window !== 'undefined' &&
    window.location &&
    window.location.hostname
  ) {
    if (window.location.hostname.includes('dev-')) {
      return 'dev';
    }
    if (
      window.location.hostname.includes('uat-') ||
      window.location.hostname.includes('merck-engage.app-trialscope.com')
    ) {
      return 'uat';
    }
  }
  return 'prod';
};

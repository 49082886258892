import React from 'react';
import {
  Container,
  ProgramWebsitesContainer,
} from './program-websites-menu.css';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { getCompanyData } from '../../constants/config';
import { registerTrackEvent } from '../../helpers/tagManager';

const ProgramWebsitesMenu = ({ data }) => {
  return (
    <Container>
      <ProgramWebsitesContainer>
        {data.programWebsitesJson.websites.map((website, index) => {
          const url = getCompanyData(website, 'url');
          return (
            <div className={'website'} key={index}>
              <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Clinical Trial Program Websites',
                  eventExternalUrl: website.logo.logoAlt || url,
                })}
              >
                <Img
                  fluid={website.logo.childImageSharp.fluid}
                  alt={website.logo.logoAlt}
                />
              </a>
            </div>
          );
        })}
      </ProgramWebsitesContainer>
    </Container>
  );
};
const ProgramWebsitesMenuQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        programWebsitesJson {
          websites {
            logo {
              childImageSharp {
                fluid(maxHeight: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            logoAlt
            url
            url_merck
          }
        }
      }
    `}
    render={data => <ProgramWebsitesMenu data={data} {...props} />}
  />
);
ProgramWebsitesMenu.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProgramWebsitesMenuQuery;

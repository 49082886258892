import { Dialog } from '@reach/dialog';
import styled from 'styled-components';

export const ConditionsModalDialog = styled(Dialog)`
  width: 80vw !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  &.redirect {
    width: 50vw !important;
  }
  &.view-results {
    max-width: 826px;
    padding: 0px;
    &.only-docs {
      max-width: 413px;
    }
    .documents-modal {
      position: relative;
      .closeSvg {
        position: absolute;
        right: 48px;
        top: 24px;
        z-index: 5;
      }
    }
    .docs-details .closeSvg {
      right: 24px;
      top: 24px;
    }
    .content-modal {
      margin-bottom: 30px;
    }
  }
  &.conditions {
    @media (min-width: 1300px) {
      min-width: 1200px !important;
    }
    padding: 60px;
    @media (max-width: 1024px) {
      width: 100vw !important;
      min-height: 100vh;
      margin: 0 !important;
      padding-bottom: 120px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &.criteriaModal {
    width: 70vw !important;
    padding-bottom: 60px;
  }
  &.contactUs {
    width: 65vw !important;
  }
  .documents-modal {
    .closeSvg {
      font-size: 24px;
      float: right;
      cursor: pointer;
      color: #eb3c96;
      &:hover {
        color: #a52a69;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    margin-right: 90px !important;
    margin-top: 100px !important;
    &.redirect {
      width: 78vw !important;
    }
    &.criteriaModal {
      width: 85vw !important;
      margin-right: auto !important;
    }
    &.contactUs {
      width: 85vw !important;
      margin-right: auto !important;
    }
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 95vw !important;
    margin-top: 20px !important;
    margin-bottom: 130px !important;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    &.redirect {
      width: 90vw !important;
    }
    &.contactUs {
      width: 100vw !important;
      height: 100vh;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &.criteriaModal {
      width: 100vw !important;
      min-height: 100vw !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 20px !important;
    }
  }
`;

export const Button = styled.button`
  -webkit-appearance: none;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  border: 1px solid #ddd;
  cursor: pointer;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 4rem 0 2rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  transition: 0.2s background-color ease;
  &:active,
  &:focus {
    outline: none;
  }
  svg {
    float: right;
    margin-top: 5px;
  }
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }

  & + & {
    margin-left: 1rem;
  }
`;

export const Close = styled.button`
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  font-weight: normal;
  padding: 1rem;
  line-height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

import styled from 'styled-components';
import {
  borderGrey,
  headerPurple,
  black,
  white,
  titlePink,
} from '../../constants/theme';

export const ContainerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: fixed;
  z-index: 1000;
  width: 100%;
  .header-menu {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    .top-menu {
      background-color: ${headerPurple};
      justify-content: center;
      display: flex;
      .header-container {
        max-width: 1220px;
        width: 100%;
      }
    }
    .menu-list {      
    border-bottom: 1px solid ${borderGrey};
      padding: 32px 0;
      justify-content: center;
      background-color: ${white};
      display: flex;
      .header-container {
        max-width: 1220px;
        width: 100%;
      }
    }
  }
  a,
  .program-websites {
    color: ${black};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 2px;
    text-decoration: none;
    svg {
      font-size: 31px;
      color: ${headerPurple};
      &:hover {
        color: #eb3c96;
      }
      &.active {
        color: #eb3c96;
      }
    }
    &:hover {
      color: #eb3c96;
    }
    &.active {
      color: #eb3c96;
    }
  }
  .program-websites {
    background: transparent;
    border: none;
    outline: none;
    svg{
      color:${titlePink};
      font-size: 17px;
    }
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
  }
    @media (max-width:992px){
      padding: 0;
    }
  }
  .logo-img {
    position: absolute;
    top: 10px;
    margin-left: 10px;
    width: 120px;
    padding: 0;
    .gatsby-image-wrapper {
      overflow: visible !important;
    }
    .accessibility-feature {
      display: none;
    }
    img {
      width: auto !important;
      height: 31px !important;
    }
    .gatsby-image-wrapper {
      & > img {
        display: none;
      }
    }
  }
  //STYLE OF FIND A TRIAL BUTTON TOPLINK
  .top-links {
    float: right;
    color: ${white};
    opacity: 0.6;
    font-weight: normal;
    line-height: 40px;
    padding-right: 5px;
    svg {
      color: ${white};
      margin-left: 5px;
      opacity: 1 !important;
      &:hover {
        color: ${white};
      }
    }
    &:hover {
      color: ${white};
      opacity: 1;
    }
    &:nth-child(3){
      padding-right:30px;
    }
  }
  @media print{
    display: none;
  }
  @media (max-width: 991px) {
    justify-content: space-between;
    .header-menu-row {
      position: absolute;
      width: 100%;
    }
    .navbar-toggler {
      padding: 0 !important;
    }
  }
  @media (min-width: 320px) and (max-width: 992px) {
    .header-menu {
      .menu-list {
        padding: 0 0;
      }
      .top-menu {
        min-height: 50px;
      }
    }
    .logo-img {
      margin-left: 5px;
    }
  }
  //mobile changes
  @media (min-width: 320px) and (max-width: 480px) {
    .logo-img {
      margin-top: 4px;
      img {
        height: 25px !important;
      }
    }
    .header-menu{
      .top-menu{
        .header-container{
          display:flex;
          flex-direction: column;
        }        
      }
    }
    .navbar-collapse {
      padding: 40px 20px 10px 20px !important;
      .item {
        padding: 15px 0 !important;
      }
    }
    .top-links {
      line-height:20px;
      width:fit-content;
      align-self: flex-end;
      &:nth-child(3){
        padding-right:11px;
      }
  }

  //TABLET LANDSCAPE
  @media (min-width: 776px) and (max-width: 1024px) and (orientation: landscape) {
    .logo-img {
      margin-left: 0px;
    }
  }

  //IPAD PRO
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .logo-img {
      margin-left: 0px;
    }
  }
`;

import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Container,
  NavSection,
  LogoSection,
  SocialSection,
  PowerZone,
} from './footer.css';
import Nav from './nav/nav';
import { Row, Col } from 'reactstrap';
import Modal from '../modal/modal';
import Redirect from '../redirect/redirect';
import { getCompanyData } from '../../constants/config';
import { registerTrackEvent } from '../../helpers/tagManager';

const Footer = ({
  data,
  copyright,
  powered,
  informaUrl,
  selectedLanguage,
  facebook,
  twitter,
  linkedin,
  special,
  psmrCode,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const showModal = () => {
    setModalOpen(true);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  let footerMenu = getCompanyData(data.site.siteMetadata, 'footerMenu');
  const footerItem = data.pageMetadataJson.pages
    .filter(page => {
      return (
        page.pageLang === selectedLanguage && footerMenu.includes(page.url)
      );
    })
    .sort((a, b) => {
      return footerMenu.indexOf(a.url) - footerMenu.indexOf(b.url);
    });

  return (
    <Container className={special ? ' special-margin' : ''}>
      <div className={'footer-content'}>
        <LogoSection>
          <img src="/images/Merk-logo.png" alt="test" />
        </LogoSection>
        <Row>
          <Col className={'nav-section'} sm={6}>
            <NavSection>
              <Nav footerItem={footerItem} />
            </NavSection>
          </Col>
          <Col className={'nav-section nav-social'} sm={6}>
            <SocialSection>
              <a
                className={'linkedin'}
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Linkedin',
                })}
              >
                <img src="/images/linkedin.svg" alt="Linkedin" />
              </a>
              <a
                className={'twitter'}
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Twitter',
                })}
              >
                <img src="/images/twitter.svg" alt="Twitter" />
              </a>
              <a
                className={'facebook'}
                href={facebook}
                target="_blank"
                rel="noopener noreferrer"
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: 'Facebook',
                })}
              >
                <img src="/images/facebook.svg" alt="Facebook" />
              </a>
            </SocialSection>
          </Col>
        </Row>
        <PowerZone>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p>{copyright}</p>
            {psmrCode.length > 1 ? (
              <p className={'psmr-code'}>{psmrCode}</p>
            ) : (
              ''
            )}
          </div>
          <div className={'power-zone'}>
            <span>{powered}</span>
            <Modal
              button={
                <img
                  src="/images/citeline_logo.svg"
                  alt="Citeline Logo"
                />
              }
              hideModal={hideModal}
              showModal={showModal}
              open={modalOpen}
              type={'redirect'}
              trackEvent={{
                label: "Leaving website"
              }}
            >
              <Redirect hideModal={hideModal} url={informaUrl} />
            </Modal>
          </div>
        </PowerZone>
      </div>
    </Container>
  );
};

const FooterWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            footerMenu
            footerMenu_merck
          }
        }
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);

export default FooterWithQuery;

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  copyright: PropTypes.string.isRequired,
  powered: PropTypes.string.isRequired,
  informaUrl: PropTypes.string.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  facebook: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  linkedin: PropTypes.string.isRequired,
  special: PropTypes.bool.isRequired,
  psmrCode: PropTypes.string.isRequired,
};

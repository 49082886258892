import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from '../../components/head/head';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import GlobalStyle from 'global.css.js';
import { Location } from '@reach/router';
import { getCompanyData } from '../../constants/config';

const Layout = ({ data, children, meta = {} }) => {
  useEffect(() => {
    let hasBanner = false;
    const interval = setInterval(() => {
      const banner = document.getElementsByClassName('osano-cm-dialog');
      if (banner && banner.length > 0 && !hasBanner) {
        hasBanner = true;
        const preferences = document.getElementsByClassName(
          'osano-cm-button--type_manage'
        );
        if (preferences && preferences.length > 0) {
          preferences[0].setAttribute('style', 'visibility:visible');
          preferences[0].innerHTML = 'Cookies Settings';
        }
        clearInterval(interval);
      }
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <div>
      <GlobalStyle />
      <Head {...meta} />
      <Location>
        {({ location }) => (
          <div>
            {location.pathname.split('/')[1] === '500' ? (
              ''
            ) : (
              <Header
                title={data.site.siteMetadata.siteTitle}
                logo={getCompanyData(data.headerJson, 'logo')}
                selectedLanguage={
                  location.pathname.split('/')[1] === '404'
                    ? data.site.siteMetadata.languages.defaultLangKey
                    : location.pathname.split('/')[1] ||
                      data.site.siteMetadata.languages.defaultLangKey
                }
                mainMenu={data.site.siteMetadata.mainMenu}
                topLinksMenu={data.site.siteMetadata.topLinksMenu}
              />
            )}
            <div style={{ overflow: 'hidden' }} className={'website-container'}>
              {children}
            </div>
            {location.pathname.split('/')[1] === '500' ? (
              ''
            ) : (
              <Footer
                special={location.pathname.indexOf('find-a-trial') > 1}
                copyright={`Copyright ${new Date().getFullYear()} ${getCompanyData(
                  data.footerJson,
                  'copyright'
                )}`}
                informaUrl={data.footerJson.informaUrl}
                powered={data.footerJson.powered}
                selectedLanguage={
                  location.pathname.split('/')[1] === '404'
                    ? data.site.siteMetadata.languages.defaultLangKey
                    : location.pathname.split('/')[1] ||
                      data.site.siteMetadata.languages.defaultLangKey
                }
                facebook={getCompanyData(data.footerJson, 'facebook')}
                twitter={getCompanyData(data.footerJson, 'twitter')}
                linkedin={getCompanyData(data.footerJson, 'linkedin')}
                psmrCode={getCompanyData(data.footerJson, 'psmrCode')}
              />
            )}
          </div>
        )}
      </Location>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
  meta: PropTypes.object,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
            mainMenu
            topLinksMenu
            languages {
              defaultLangKey
              langs
              defaultPageUrl
            }
          }
        }
        headerJson {
          title
          logo {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          logo_merck {
            childImageSharp {
              fluid(maxWidth: 300, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        footerJson {
          copyright
          copyright_merck
          psmrCode
          psmrCode_merck
          powered
          informaUrl
          facebook
          facebook_merck
          twitter
          twitter_merck
          linkedin
          linkedin_merck
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object,
};

export default LayoutWithQuery;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 7px 0;
  @media (min-width: 993px) {
    background: #f9f9fd;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
      0 10px 20px 0 rgba(0, 0, 0, 0.1);
    padding: 23px 0;
  }
  @media (max-width: 992px) {
    padding: 5px 0;
  }
  display: flex;
  justify-content: center;
`;

export const ProgramWebsitesContainer = styled.div`
  max-width: 1220px;
  width: 100%;
  display: flex;
  padding: 7px 15px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  .website {
    padding: 7px 20px;
    .gatsby-image-wrapper {
      width: 140px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      &>div{
        display: none;
      }
      img {
        height: auto !important;
        position: relative !important;
      }
    }
  }
  @media (min-width: 992px){
    .website{
      &:last-child{
        padding-right: 5px; 
      }
    }
  }
  @media (max-width: 992px) {
    padding: 0;
    justify-content: flex-start;
  }
  @media (max-width: 420px) {
    justify-content: space-around;
    .website {
      width: 70%;
      .gatsby-image-wrapper {
        width: 100%;
        height: auto;
        picture{
          width: 100%;
        }
      }
    }
  }
  @media (min-width: 421px) and (max-width: 992px) {
    .website {
      padding: 7px 40px 7px 0;
    }
  }
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    padding: 0px 2px 0px 0px;
  }
`;

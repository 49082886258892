import React from 'react';
import { Container } from './nav.css';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { registerTrackEvent } from '../../../helpers/tagManager';

const Nav = ({ footerItem }) => {

  return (
    <Container>
      <ul>
        {footerItem.map((item, index) => (
          <li key={index}>
            {item.url.indexOf('faq') > -1 ? (
              <NavLink
                href={`/en/${item.url}/`}
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: item.menuTitle,
                })}
              >
                {item.menuTitle}
              </NavLink>
            ) : (
              <a 
                href={`${item.url}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={registerTrackEvent({
                  category: 'Navigation',
                  action: 'Click',
                  label: item.menuTitle,
                })}
              >
                {item.menuTitle}
              </a>
            )}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Nav;

Nav.propTypes = {
  footerItem: PropTypes.any,
};

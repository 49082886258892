import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from './nav.css';
import { StaticQuery, graphql } from 'gatsby';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { IoIosClose, IoIosArrowDown } from 'react-icons/io';
import { MdHome, MdMenu } from 'react-icons/md';
import { Location } from '@reach/router';
import ProgramWebsitesMenu from '../program-websites-menu/program-websites-menu';
import { registerTrackEvent } from '../../helpers/tagManager';

const SiteNav = ({
  selectedLanguage,
  menuItems,
  isProgramWebsitesMenuOpen,
  toggleProgramWebsitesMenu,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLocation = useRef([]);

  const renderSimpleMenuItem = page => {
    const onlyActives = currentLocation.current.slice(1, 3);
    const isActive = onlyActives.includes(page.url);

    return (
      <div key={page.url} className={'item'}>
        <NavItem
          className={
            'nav-item-li ' +
            (page.url === '' ? ' home-icon' : page.url === 'faq' ? 'faqs' : '')
          }
        >
          {page.url === '' ? (
            <NavLink
              href={`/${selectedLanguage}/`}
              onClick={registerTrackEvent({
                category: 'Navigation',
                action: 'Click',
                label: 'Logo',
              })}
            >
              <span className={'accessibility-feature'}>Logo</span>
              <MdHome className={isActive ? 'active' : ''} />
            </NavLink>
          ) : (
            <NavLink
              href={`/${selectedLanguage}/${page.url}/`}
              className={isActive ? 'active' : ''}
              onClick={registerTrackEvent({
                category: 'Navigation',
                action: 'Click',
                label: page.menuTitle,
              })}
            >
              {page.menuTitle}
            </NavLink>
          )}
        </NavItem>
      </div>
    );
  };

  const renderItem = (page, index, menuItemList) => {
    return renderSimpleMenuItem(page, index, menuItemList);
  };

  return (
    <Container>
      <Location>
        {({ location }) => {
          currentLocation.current = location.pathname.split('/');

          return (
            <Navbar expand="lg">
              {menuItems.length > 2 &&
                (isOpen === false ? (
                  <NavbarToggler
                    onClick={() => setIsOpen(!isOpen)}
                    className={'burger-toggler'}
                  >
                    <span className={'accessibility-feature'}>Logo</span>
                    <MdMenu />
                  </NavbarToggler>
                ) : (
                  <NavbarToggler
                    onClick={() => setIsOpen(!isOpen)}
                    className={'burger-toggler'}
                  ><span className={'accessibility-feature'}>Logo</span>
                    <IoIosClose />
                  </NavbarToggler>
                ))}
              <Collapse
                className={'collapse navbar-collapse'}
                id="navbarCollapse"
                isOpen={menuItems.length > 1 ? isOpen : true}
                navbar
              >
                <Nav className="ml-auto" navbar>
                  {menuItems
                    .filter(
                      page =>
                        typeof page.parent === 'undefined' ||
                        page.parent === null
                    )
                    .map((page, index) => renderItem(page, index, menuItems))}
                  <div className="item">
                    <NavItem className="nav-item-li">
                      <div className="nav-link">
                        <button
                          className={
                            'program-websites ' +
                            (isProgramWebsitesMenuOpen ? ' active' : ' ')
                          }
                          onClick={toggleProgramWebsitesMenu}
                        >
                          {'Clinical Trial Program Websites'}
                          <IoIosArrowDown />
                        </button>
                      </div>
                    </NavItem>
                    {isProgramWebsitesMenuOpen && isMobile ? (
                      <ProgramWebsitesMenu />
                    ) : null}
                  </div>
                </Nav>
              </Collapse>
            </Navbar>
          );
        }}
      </Location>
    </Container>
  );
};

const NavWithQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        pageMetadataJson {
          pages {
            menuTitle
            url
            pageLang
          }
        }
      }
    `}
    render={data => <SiteNav data={data} {...props} />}
  />
);

export default NavWithQuery;

SiteNav.propTypes = {
  data: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.any.isRequired,
  menuItems: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isProgramWebsitesMenuOpen: PropTypes.bool.isRequired,
  toggleProgramWebsitesMenu: PropTypes.func.isRequired,
};
